import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../../images/Asset 18.svg';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const NAvbar = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const isLoggedIn = localStorage.getItem('userId') !== null;
  const [searchQuery, setSearchQuery] = useState('');
  const [keyword, setKeyword] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const user_type = localStorage.getItem('userType');


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchQuery.trim() !== '') {
          const response = await axios.get(`${process.env.REACT_APP_API}/api/search/r?query=${searchQuery}`);
          setSearchResults(response.data.ResponseData);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error searching:', error);
      }
    };

    fetchData();
  }, [searchQuery]);
  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('clientId');
    localStorage.removeItem('userType');
    localStorage.removeItem('company_id');
    localStorage.removeItem('companyId');
    navigate('/login');
  };

  const [showNav, setShowNav] = useState(false);
  const handleToggleNav = () => setShowNav((prev) => !prev);
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleItemClick = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <section className='pt-2 pb-2 ' style={{ boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)", backgroundColor: "var(--gray)" }}>

        <Container fluid className='ps-xxl-5 pe-xxl-5' >
          <div className='row w-100 justify-content-between align-items-center' >
            <div className='col-2'>
              <Link to="/" className="navbar-brand ">
                <h3 className='text-white'>PG Click</h3>
                {/* <img src={logo} alt="" className='pointer ' /> */}
              </Link>
            </div>
            <div className='col-4 justify-content-center align-items-center d-none d-lg-flex d-xl-flex d-xxl-flex  position-relative'>
              <input
                type="text"
                className="form-control w-100 "
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={handleToggleDropdown}
              />  {isLoading && <p>Loading...</p>}
              {searchResults.length > 0 && showDropdown && (
                <div className="bg-white position-absolute shadow" style={{ zIndex: 1, width: "100%", top: "100%" }}>
                  {searchResults.map((result) => (
                    <ul className="" key={result.id} >
                      <div className="" onClick={handleItemClick}>
                        <Link to={`/productview/${result.id}`}>
                          <p className="text-black" >{result.name}</p>
                        </Link>
                      </div>
                    </ul>
                  ))}
                </div>
              )}

            </div>
            <div className='col-5 d-none d-lg-flex d-xl-flex d-xxl-flex justify-content-end '>
              <Nav.Link className="me-3" onClick={() => navigate('/cart')}>
                About
              </Nav.Link>
              <Nav.Link className="me-3" onClick={() => navigate('/eventexhibition')}>
                Event
              </Nav.Link>
              <Nav.Link className="me-3" onClick={() => navigate('/busineemeeting')}>
                Metting
              </Nav.Link>
              {user_type === '1' && (
                <Nav.Link className="me-3" onClick={() => navigate('/userdashboard')}>
                  Go to Profile
                </Nav.Link>
              )}
              {user_type === '2' && (
                <Nav.Link className="me-3" onClick={() => navigate('/dashboard')}>
                  Seller Panel
                </Nav.Link>
              )}
            </div>
            <div className='col-1  d-none d-lg-flex d-xl-flex d-xxl-flex'>
              <div className='d-flex gap-2'>
                {isLoggedIn ? (
                  <Button className='btngray1 sign' onClick={handleLogout}><span>Logout</span></Button>
                ) : (
                  <Button className='btngray1 sign' onClick={() => navigate('/login')}><span>Login</span></Button>
                )}

              </div>
            </div>
          </div>

          {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
          {/* <Navbar.Collapse id="navbarScroll" className="justify-content-end">
              <Nav className="me-auto my-2 my-lg-0 " style={{ maxHeight: '100px' }}  >
              </Nav> */}



          {/* </Navbar.Collapse> */}
        </Container>

      </section>
    </>
  )
}

export default NAvbar
