import React, { useState, useEffect } from 'react'
import { TextField, useTheme } from '@mui/material'
import leftimage from '../../images/269143-P5KU6U-596-removebg-preview (1).png'
import { Container  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import style from '../Login/Login.module.css';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function BusinessDetails() {
  const navigate = useNavigate();
  const [cityData, setcityData] = useState([]);
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    city_id: '',
  });
  const [authenticated, setAuthenticated] = useState(false); 

  useEffect(() => {
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/city-list`);
            setcityData(response.data.ResponseData);
        } catch (error) {
            console.error('Error fetching categories: ', error);
        }
    };
    fetchCategories();
    const isUserAuthenticated = localStorage.getItem('userId');
    if (isUserAuthenticated) {
      setAuthenticated(true);
    }
}, []);

useEffect(() => {
  if (authenticated) {
    navigate('/');
  }
}, [authenticated, navigate]);

const handleFormSubmit = () => {

  if (!formData.full_name) {
    toast.error('Name is required');
    return;
  }

  if (!formData.city_id) {
    toast.error('City is required');
    return;
  }

  const apiUrl = `${process.env.REACT_APP_API}/api/user-add/${localStorage.getItem('userId')}`;
  fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((data) => {
      navigate('/');     
      console.log(data);
      toast.success('Business details added successfully');
    })
    .catch((error) => {
      console.error('Error:', error);
      toast.error('Error adding business details');
    });
};

  return (
    <>
      <section>
        <Container fluid  >
          <div className='row mt-50 leftImage'>
            <div className='col-12 col-lg-6 col-md-6 col-xl-5 mt-3 '>
              <div className='BusinessFlex'>
                <img className={style.loginimage} src={leftimage} alt=""  />
              </div>
            </div>
            {/* <div className='col-xl-1'></div> */}
            <div className='col-12 col-lg-6 col-md-6 col-xl-6 mt-5 leftImage ms-7'>
              <h3 className='mb-5'>Enter your business details</h3>
              <div>
                <div className='d-flex flex-column  w-75 busidetail'>
                  <TextField
                    helperText=" "
                    id="demo-helper-text-aligned-no-helper"
                    label="Name"
                    name='full_name'
                    onChange={(event) => setFormData({ ...formData, full_name: event.target.value })}
                    required  
                  />
                  <TextField
                    helperText=" "
                    id="demo-helper-text-aligned-no-helper"
                    label="Email"
                    name='email'
                    onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                  />
                  
                  <select name="city_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => setFormData({ ...formData, city_id: e.target.value })} data-placeholder="Select" required>
                    <option value={formData.city_id ? "" : ""}>
                       City
                    </option>
                    {cityData &&
                      cityData.map((v, index) => {
                        return (
                          <option value={v.id} key={index.id}>
                            {v.cityname}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='d-flex'>
                  <button className='btn btnpink mt-5 text-white w-25 py-2' onClick={handleFormSubmit} >Submit</button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <ToastContainer />
    </>
  )
}

export default BusinessDetails
