import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';
import { Link } from 'react-router-dom';
import style from '../ImagePicker/ImagePicker.module.css'

const Video = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [videoId, setVideoId] = useState(0);
    const [companyId] = useState(localStorage.getItem('clientId'));
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        fetchVideoUrl();
    }, []);

    const fetchVideoUrl = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientvideourl/${companyId}`);
            const data = await response.json();
            console.log('API Response:', data); // Log the API response for debugging
            if (data.ResponseCode === 1) {
                console.log('Video URL fetched successfully');
                setVideoUrl(data.ResponseData.video_url);
                setVideoId(data.ResponseData.user_id);
            } else {
                console.error('Error fetching video URL:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching video URL:', error);
        }
    };
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const updateClientWebsite = async (event) => {
        if (!selectedFile) {
            console.error('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('video_url', selectedFile); // Append the file to FormData

        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientwebsite-update/${companyId}`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Client website updated successfully');
                // Optionally, update the video URL and ID here based on the response
                fetchVideoUrl();
            } else {
                console.error('Error updating client website:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error updating client website:', error);
        }
    };

    return (
        <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                    <SideBar />
                </div>
                <div className='col-8 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <div className='text-end mb-3 mt-3'>
                        <Link to={'/dashboard'}>
                            <Button className='btnpink px-5'>Back</Button>
                        </Link>
                    </div>
                    <h2 className='mt-5 text-uppercase'>Video</h2>
                    <p>Update Your official Company Images. Please make sure that You are Submitting Your active Company Images only.</p>
                    <video src={videoUrl} className={style.video} controls>
                    </video>
                    <div>
                        <input type="file" accept="video/*" onChange={handleFileChange} />

                    </div>
                    <div>
                        <button className='btn btnpink mt-3 px-5' style={{ color: 'white' }} onClick={updateClientWebsite}>Update</button>

                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Video;
