import React, { useState, useEffect } from 'react';
import banner from '../../images/banner-1.jpg';
import { BottomNavigationn } from '../../Componenets/BottomNavigation';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Bottomprofile = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    full_name: '',
    phone: '',
    email: '',
    profile_pic: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/userdetails/${localStorage.getItem("userId")}`);
        const data = await response.json();
        if (data.ResponseCode === 1) {
          setUserData(data.ResponseData.userdetails);
        } else {
          console.error('Failed to fetch user details:', data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/update-user/${localStorage.getItem("userId")}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });
      const data = await response.json();
      if (data.ResponseCode === 1) {
        console.log('User data updated successfully!');
        handleCloseModal();
      } else {
        console.error('Failed to update user data:', data.ResponseText);
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  return (
    <>
      <section className='container mt-0 mt-xxl-5 mt-xl-5 mt-lg-5'>
        <div className='row'>
          <div className='col-12 border mt-1 d-flex flex-column gap-3 pb-5 '>
            <div className='d-flex justify-content-between align-items-center mt-3'>
              <div className='d-flex align-items-center gap-3' >
                <div style={{ width: '80px', height: '80px' }}>
                  {userData && userData.profile_pic ? (
                    <img src={userData.profile_pic} alt="Profile" style={{ width: '100%', borderRadius: '50%', height: '100%', objectFit: 'cover' }} />
                  ) : (
                    <img src={banner} alt="Default Profile" style={{ width: '100%', borderRadius: '50%', height: '100%', objectFit: 'cover' }} />
                  )}
                </div>
                <div className='d-flex flex-column'>
                  <span>{userData.full_name}</span>
                  <span>{userData.phone}</span>
                  <span className='fst-italic' >{userData.email}</span>
                </div>                
              </div>           
              <div>
                <button className=' btn btnpink' style={{ width: '100px', color: 'white' }} onClick={handleShowModal}>Edit</button>
              </div>            
            </div>
            <hr />
            <div className='d-flex justify-content-between align-items-center ' onClick={() => navigate('/postyourrequirement')}>
              <h4 className='d-flex align-items-center gap-2'> <i className="fa-solid fa-envelope" style={{ color: 'var(--pink)',backgroundColor: '#9ba5ad',padding: '10px', borderRadius: '50%',fontSize: '20px' }} />Post Your Requirements </h4>
              <i className="fa-solid fa-angle-right" style={{ color: 'var(--pink)' }} />
            </div>
            <div className='d-flex justify-content-between align-items-center' >
              <h4 className='d-flex align-items-center gap-2'> <i className="fa-solid fa-bell" style={{ color: 'var(--pink)',backgroundColor: '#9ba5ad',padding: '10px', borderRadius: '50%',fontSize: '20px'  }} />Notification </h4>
              <i className="fa-solid fa-angle-right" style={{ color: 'var(--pink)' }} />
            </div>

            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='d-flex align-items-center gap-2'> <i className="fa-solid fa-handshake" style={{ color: 'var(--pink)',backgroundColor: '#9ba5ad',padding: '10px', borderRadius: '50%',fontSize: '20px'  }} />Help Center </h4>
              <i className="fa-solid fa-angle-right" style={{ color: 'var(--pink)'}} />
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='d-flex align-items-center gap-2'> <i className="fa-solid fa-phone" style={{ color: 'var(--pink)',backgroundColor: '#9ba5ad',padding: '10px', borderRadius: '50%',fontSize: '20px'  }} />Contact Us </h4>
              <i className="fa-solid fa-angle-right" style={{ color: 'var(--pink)' }} />
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='d-flex align-items-center gap-2'> <i className="fa-solid fa-user"style={{ color: 'var(--pink)',backgroundColor: '#9ba5ad',padding: '10px', borderRadius: '50%',fontSize: '20px'  }} />About Us </h4>
              <i className="fa-solid fa-angle-right" style={{ color: 'var(--pink)' }} />
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='d-flex align-items-center gap-2'> <i className="fa-solid fa-circle-question" style={{ color: 'var(--pink)',backgroundColor: '#9ba5ad',padding: '10px', borderRadius: '50%',fontSize: '20px'  }} />FAQ </h4>
              <i className="fa-solid fa-angle-right" style={{ color: 'var(--pink)' }} />
            </div>
          </div>
        </div>
      </section>
      <BottomNavigationn />   
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="profilePic" className="form-label">Profile Picture</label>
            <div style={{ width: '80px', height: '80px' }} >
              {selectedImage ? (
                <img src={selectedImage} alt="Selected Profile" style={{ width: '100%', borderRadius: '50%', height: '100%', objectFit: 'cover' }} />
              ) : (
                <img src={banner} alt="Default Profile" style={{ width: '100%', borderRadius: '50%', height: '100%', objectFit: 'cover' }} />
              )}
            </div>
            <input type="file" className="form-control" id="profilePic" name="profile_pic" accept="image/*" onChange={handleImageChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input type="text" className="form-control" id="fullName" name="full_name" value={userData.full_name} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" name="email" value={userData.email} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
            <input type="number" className="form-control" id="phoneNumber" name="phone" value={userData.phone} onChange={handleChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdate}>Update</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Bottomprofile;
