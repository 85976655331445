import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import style from './UpcomingTradeshow.module.css';
import calender from '../../../images/catender.png';
import location from '../../../images/location.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { UPCOMINGTRADESCHOW } from './UpcomingTradeshow.utils';


const UpcomingTradeshow = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min:1025},
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 941 },
      items: 3, 
    },
    tablet: {
      breakpoint: { max: 1226, min: 941 },
      items: 3,
    },
    tablett: {
      breakpoint: { max: 940, min: 622 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 621, min: 0 },
      items: 1,
    },
  };
  return (
   <>
   <section className='mt-5 mb-20'>
        <div className={style.UpcomingTradeshowFlex} >
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
        <h3 className='mb-5'>Upcoming Tradeshows</h3>
          <Carousel
           responsive={responsive}
            infinite={true} 
            autoPlay={true}
            autoPlaySpeed={2000}
            >
            {UPCOMINGTRADESCHOW.map((item, index) => (
              <div key={index} className={style.Tradeshow}>
                <img src={item.img} alt="" />
                <h3 className={style.title}>{item.title}</h3>
                <div className='d-flex align-items-center gap-2'>
                  <img src={calender} alt="" />
                  <span className={style.date}>{item.date}</span>
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <img src={location} alt="" />
                  <span className={style.date}>{item.location}</span>
                </div>
              </div>
            ))}
          </Carousel>
        </Container>
        </div>
      </section>
  </>
  )
}

export default UpcomingTradeshow
