import React, { useEffect, useState } from 'react';
import style from './BusinessMetting.module.css';
import banner from '../../images/banner-1.jpg';
import { Container } from 'react-bootstrap';
import axios from 'axios';

const BusinessMetting = () => {
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/meeting-list`);
        const { ResponseCode, ResponseData } = response.data;
        if (ResponseCode === 1) {
          setMeetings(ResponseData);
        } else {
          console.error('Error fetching meetings:', response.data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };

    fetchMeetings();
  }, []);

  const handleMeetingClick = async (meetingId, imageUrl) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/meetingwiseimagelist/${meetingId}`);
      // Handle the response data as needed
      console.log('Meeting wise image list:', response.data);
  
      // Open the image URL in a new tab
      window.open(imageUrl, '_blank');
    } catch (error) {
      console.error('Error fetching meeting wise image list:', error);
    }
  };
  return (
    <>
      <section>
        <Container fluid className='px-xxl-5'>
          <h1 className='mt-5'>Business Meeting</h1>
          <p>PG Click organizes business meetings once a month to grow business networks and referrals through predefined agreements. The meetings include two details: PowerPoint presentations or product demos.</p>
          <div className='row'>
            {meetings.map(meeting => (
              <div key={meeting.meeting_id} className='col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3'>
                 <div className={style.businessFlex} onClick={() => handleMeetingClick(meeting.meeting_id, meeting.image_url)}>
                  <img src={meeting.image_url} alt={meeting.meeting_name} className={style.businessImg} />
                  <div className='p-3'>
                    <h5 className='text-capitalize'>{meeting.meeting_name}</h5>                   
                    <span className='fw-bold' style={{ color: 'var(--pink)' }}>{meeting.sdate}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  )
}

export default BusinessMetting;
