import React, { useState, useEffect } from 'react';
import style from './OurCategory.module.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';



const OurCategory = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/home`);
        const data = await response.json();
        setCategories(data.ResponseData.categoryList);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }



  return (
    <>    
      <section >
    
  
        <Container fluid className='ps-xxl-5 pe-xxl-5 '>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='mt-1 mt-xxl-5 mt-xl-5 mt-lg-5 mb-xxl-5 mb-xl-5 mb-lg-5' data-aos="fade-up" data-aos-duration="1000">
              <h1 className='mb-2 '>Our Category</h1>
              <span className={style.categorysubtitle}>Buy and Sell Everything from Used Our Top Category</span>
            </div>
            <div className={style.view}>
              <Button className='btnpink' onClick={() => navigate('/categories')}>View All</Button>
            </div>
          </div>
          <Row className={style.rowright} data-aos="fade-up" data-aos-duration="500">
            {categories.slice(0, 6).map((item, index) => (
              <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 paddingStyle ' key={index} >
                <Link to={`/subcategories/${item.id}`}>
                <div >
                  <div className={style.CategoryFlex}>
                  <div className={style.CategoryImage}>
                  <img src={item.image} alt="" className={style.CategoryImagee} />
                  </div>
                    <h6 className={style.CategoryTitle} >{item.category_name}</h6>
                    <span>{item.adds}</span>
                  </div>
                </div>
              </Link>
              </div>
            ))}
          </Row>

        </Container>
      
      </section>
    </>
  )
}

export default OurCategory;
