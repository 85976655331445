import React from 'react'

const Backgroundanimation = () => {
  return (
  <>
   <div className="background-animation">
   <div class="area" >
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
            </div>
   </div>
  </>
  )
}

export default Backgroundanimation
