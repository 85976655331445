import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import style from './Contact.module.css';
import SideBar from '../../Common/SideBar/SideBar';
import { BottomNavigationn } from '../../Componenets/BottomNavigation';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const navigate = useNavigate();
  const [contactDetails, setContactDetails] = useState({
    contact1: '',
    contact2: ''
  });

  const fetchContactDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/clientcontact/${localStorage.getItem('clientId')}`);
      const responseData = response.data.ResponseData;
      setContactDetails({
        contact1: responseData.contact1,
        contact2: responseData.contact2
      });
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  useEffect(() => {
    fetchContactDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const updateContactDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/clientcontact-update/${localStorage.getItem('clientId')}`, contactDetails);
      if (response.data.ResponseCode == 1) {
        toast.success('Contact details updated successfully!');
        navigate('/dashboard');
      }
      console.log(response.data);
    } catch (error) {
      console.error('Error updating contact details:', error);
    }
  };
  return (
    <>
     <ToastContainer /> 
      <section>
        <Container fluid className='ps-0'>
          <div id='inquiry' className='row gap-xxl-5'>
            <div className='col-4 col-sm-3 col-md-4 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
              <SideBar />
            </div>
            <div className='col-8 col-sm-8 col-md-8 col-lg-10 col-xl-9 col-xxl-9 mt-1'>
              <div className='text-end mb-3 mt-3'>
                <Link to={'/dashboard'}>
                  <Button className='btnpink px-5'>Back</Button>
                </Link>
              </div>
              <div className={style.inquiryFlex}>
                <div>
                  <h3>Contact Details</h3>
                  <div className='d-flex align-items-center gap-3'>
                    <span className='h6 d-none d-lg-block d-xl-block d-xxl-block'>Please Note :-</span>
                    <span>Edits may go for moderation and it can take up to 24-48 hours to be published.</span>
                  </div>
                  <form>
                    <div className='mt-3'>
                      <label className='mb-2'>Add Mobile</label>
                      <input
                        type='text'
                        className='form-control w-25 w-101'
                        name="contact1"
                        value={contactDetails.contact1}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='mt-3'>
                      <label className='mb-2'>Alternate Mobile</label>
                      <input
                        type='text'
                        className='form-control w-25 w-101'
                        name="contact2"
                        value={contactDetails.contact2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='mt-3'>
                      <Button className='btnpink' onClick={updateContactDetails}>
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <BottomNavigationn />
    </>
  );
};

export default Contact;
