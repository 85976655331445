import React from 'react'
import Banner from './Banner/Banner'
import OurCategory from './OurCategory/OurCategory'
import OurServices from './OurServices/OurServices'
import AdvertisementBanner from './AdvertisementBanner/AdvertisementBanner'
import FeaturedProduct from './FeaturedProduct/FeaturedProduct'
import FeaturedProducts2 from './FeaturedProducts2/FeaturedProducts'
import FeatureProducts3 from './FeatureProducts3/FeatureProducts3'
import Testimonial from './Testimonial/Testimonial'
import UpcomingTradeshow from './UpcomingTradeshow/UpcomingTradeshow'
import OurPricingPlan from './OurPricingPlan/OurPricingPlan'
import Footer from '../../Componenets/Layout/Footer/Footer'
import NAvbar from '../../Componenets/Layout/Navbar/NAvbar'
import BottomNavigation from '../../Componenets/BottomNavigation/BottomNavigation'

const Home = () => {
  return (
    <>
     <Banner />
      <OurCategory />
      <FeaturedProduct />
      <AdvertisementBanner />
      <FeatureProducts3 />
      <Testimonial />
      <UpcomingTradeshow />
      {/* <OurPricingPlan /> */}
      <BottomNavigation />
    </>
  )
}

export default Home
