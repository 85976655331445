import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import style from './Inquiry.module.css';
import SideBar from '../../Common/SideBar/SideBar';
import BottomNavigationn from '../../Componenets/BottomNavigation/BottomNavigation';

const Inquiry = ({ item }) => {
    const [inquiryList, setInquiryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companyId] = useState(localStorage.getItem('clientId'));

    useEffect(() => {
        fetchInquiryList();
    }, []);

    const fetchInquiryList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/receive-Inquirylist/${companyId}`);
            const data = await response.json();
            if (data.ResponseCode === 1) {
                setInquiryList(data.ResponseData);
            } else {
                console.error('Error fetching inquiry list:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching inquiry list:', error);
        }
    };
    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString();
    };

    const indexOfLastInquiry = currentPage * 10;
    const indexOfFirstInquiry = indexOfLastInquiry - 10;
    const currentInquiries = inquiryList.slice(indexOfFirstInquiry, indexOfLastInquiry);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <section>
                <Container fluid className='ps-0'>
                    <div id='inquiry' className='row gap-xxl-5'>
                        <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                            <SideBar />
                        </div>
                        <div className='col-8 col-sm-8 col-md-9 col-lg-10 col-xl-9 col-xxl-9 mt-1'>
                            {/* <div className='text-end mb-3 mt-3'>
                                <Button className='btnpink px-5'>Inquiry</Button>
                            </div> */}
                            {currentInquiries.map((item, index) => (
                                <div key={index} className={style.inquiryFlex}>
                                    <div>
                                        <div className='d-flex flex-column align-items-start'>
                                            <div className='d-flex align-items-center justify-content-between w-100 gap-3'>
                                                <span style={{ color: '#C10037' }}>{item.full_name}</span>
                                                <span >{formatDate(item.created_at)}</span>
                                            </div>
                                            <h5>{item.name}</h5>
                                            <span>{item.phone}</span>
                                            <p>
                                                {window.innerWidth <= 650
                                                    && item.description.length > 30
                                                    ? item.description.slice(0, 30) + '...'
                                                    : item.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="pagination d-flex justify-content-end gap-2">
                                {Array.from({ length: Math.ceil(inquiryList.length / 10) }, (_, i) => (
                                    <button className={`px-2 py-1 ${currentPage === i + 1 ? 'btnpink' : ''}`} key={i} onClick={() => paginate(i + 1)}>{i + 1}</button>
                                ))}
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            <BottomNavigationn />

        </>
    );
};

export default Inquiry;
