import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const PostYourRequirementfooter = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        companyName: '',
        requirement: '',
        comment: '',
        cityName: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
       
        console.log('Form Data:', formData);
    };
  return (
   <>
    <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                </div>
                <div className='col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <h2 className='mt-5 text-uppercase'>Post Your Requirement</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" name="name" value={formData.name} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMobileNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter your mobile number" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCompanyName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your company name" name="companyName" value={formData.companyName} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRequirement">
                            <Form.Label>Requirement</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter your requirement" name="requirement" value={formData.requirement} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formComment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter any additional comment" name="comment" value={formData.comment} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCityName">
                            <Form.Label>City Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your city name" name="cityName" value={formData.cityName} onChange={handleChange} />
                        </Form.Group>
                        <Button className='btn btnpink' type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
   </>
  )
}

export default PostYourRequirementfooter
