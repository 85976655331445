import React, { useEffect, useState } from 'react';
import style from './Featuredproduct.module.css';
import { Button, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import inquiry from '../../../images/SVG.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const FeaturedProduct = () => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [featuredProducts, setFeaturedProducts] = useState([]);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/home`
    )
      .then((response) => response.json())
      .then((data) => {
        setFeaturedProducts(data.ResponseData.feedList);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleProductClick = async (productId) => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/add-visitor`, {
        user_id: userId,
        productid: productId
      });
      console.log(response.data); 
    } catch (error) {
      console.error("Error adding visitor:", error);
    }
  };

  return (
    <>
      <section data-aos="fade-up" data-aos-duration="2000">
        {featuredProducts &&
          featuredProducts.map((feed) => (
            feed.products_list && feed.products_list.length > 0 && (
              <Container fluid className='ps-xxl-5 pe-xxl-5'>
                <h3 className='mt-3 mb-3  mt-xxl-5 mt-xl-5 mt-lg-5' >{feed.feedname}</h3>
                <Row>
                  {feed.products_list.map((product, index) => (
                    <div className='col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-xxl-2 paddingStyle '>
                      <Link to={`/productview/${product.id}`} key={index} onClick={() => handleProductClick(product.id)}>
                        <div className={style.FeaturedFlex} key={index}>
                          <div className={style.ProductImagee}>
                            <img src={product.product_image} className={style.ProductImage} alt="" />
                          </div>
                          <div className='d-flex flex-column  w-100 px-2'>
                          <span className={style.ProductTitle}>
                        {product.name.length > 25 ? product.name.slice(0, 25) + '...' : product.name}
                      </span>
                            <span className={style.ProductPrice}>
                        Price:- {product.price ? `₹${product.price}` : 'Ask Price'}
                      </span>
                            {/* <span>{product.pieces}</span>
                            <span className={style.ProductBrand}>{product.brand}</span> */}
                          </div>
                          <div className='d-none d-lg-flex d-xl-flex d-xxl-flex justify-content-center w-75 position-absolute ' style={{ bottom: '20px' }}>
                          <Button className='w-75 btngray1 inquirysend'>Get Best Price</Button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Row>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  {feed.banner_list &&
                    feed.banner_list.map((banner, bannerIndex) => (
                      <Carousel.Item key={bannerIndex}>
                        <div className='mt-0 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5' data-aos="fade-up" data-aos-duration="2000">
                          <div className={style.BannerFlex}>
                            <img className={style.AdvertisementBanner} src={banner.feedbanner_image} alt="" 
                            onClick={() => navigate(`/productdetails/${banner.link_id}`)} 
                            />
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </Container>
            )
          ))}
      </section>

    </>
  )
}

export default FeaturedProduct
