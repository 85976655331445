import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../images/logo.svg.png'
import style from './Dashboard.module.css'
import team from '../../images/caresouel-3.png'
import user from '../../images/user.png'
import dots from '../../images/therrdot.png'
import arrow from '../../images/arrow-up.svg.png'
import graph from '../../images/graph1.png.png'
import { useNavigate } from 'react-router-dom';
import SideBar from '../../Common/SideBar/SideBar';
import LeaderBoard from '../LeaderBoard/LeaderBoard';


const Dashboard = () => {
    const navigate = useNavigate()
    return (
        <>
            <section>
               <Container fluid className='ps-0'>            
               <div className='row' id='dashboard'>
                    <div className='col-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2 dashboardLeft mt-1' >
                        <SideBar />
                    </div>
                    <div className='col-7  col-md-8 col-lg-9 col-xl-9 col-xxl-10 dashboardRight mt-1 '>
                        <LeaderBoard />                                       
                    </div>
                </div>
               </Container>
            </section>
        </>
    )
}

export default Dashboard
