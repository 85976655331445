export const BUYLEADS = [
    {
        title: "Custom Website Development Service",
        description: "I am looking for Custom Website Development Service. Kindly send me price and other details.",  
    },
    {
        
        title: "Custom Website Development Service",
        description: "I am looking for Custom Website Development Service. Kindly send me price and other details.",
    },
    {
        title: "Custom Website Development Service",
        description: "I am looking for Custom Website Development Service. Kindly send me price and other details.",  
    },
    {
        
        title: "Custom Website Development Service",
        description: "I am looking for Custom Website Development Service. Kindly send me price and other details.",
    }
]