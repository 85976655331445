import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import style from './Banner.module.css';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/banner-list`);
        setBanners(response.data.ResponseData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {banners.map((banner) => (
          <Carousel.Item>
            <div className={style.BannerFlex}>
            <img
          src={banner.image}
          alt={`Banner ${banner.id}`}
          className={style.bannerImsage}
          onClick={() => navigate(`/productdetails/${banner.bannder_link}`)} 
        />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

export default Banner
