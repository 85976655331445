import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const BusinessWebsite = () => {
    const [website, setWebsite] = useState('');
    const [companyId] = useState(localStorage.getItem('clientId'));

    useEffect(() => {
        fetchWebsiteUrl();
    }, []);

    const fetchWebsiteUrl = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientwebsiteurl/${companyId}`);
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Website URL fetched successfully');
                setWebsite(data.ResponseData.web_url);
            } else {
                console.error('Error fetching website URL:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching website URL:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientwebsite/${companyId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ web_url: website })
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Client website updated successfully');
            } else {
                console.error('Error updating client website:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error updating client website:', error);
        }
    };

    const handleChange = (event) => {
        setWebsite(event.target.value);
    };

    return (
        <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                    <SideBar />
                </div>
                <div className='col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <h2 className='mt-5 text-uppercase'>Business Website</h2>
                    <p>Update Your official business website. Please make sure that You are Submitting Your active business website only.</p>
                    <Form onSubmit={handleSubmit} className='w-50'>
                        <Form.Group className="mb-3" controlId="formWebsite">
                            <Form.Label><FontAwesomeIcon icon={faGlobe} /> Website</Form.Label>
                            <Form.Control type="text" placeholder="Enter website URL" value={website} onChange={handleChange} />
                        </Form.Group>
                        <Button className='btn btnpink' type="submit">
                            Save
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
}

export default BusinessWebsite;
