import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import style from './ContactUsFooter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapLocation, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons';
import Backgroundanimation from '../../Backgroundanimation';


const ContactUsFooter = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact_number: '',
    email_id: '',
    description: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log('API Response:', data);
      
      // Clear form fields after successful submission
      setFormData({
        name: '',
        contact_number: '',
        email_id: '',
        description: ''
      });
  
      // Handle success response here
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <section>
      <Backgroundanimation />
      <h2 className='mt-5 text-uppercase text-center'>Contact Us</h2>
      <p className='text-center'>Get in touch with us</p>
      <Container >
        <div className='row '>
          <div className='col-12  col-lg-6 col-xl-6 col-xxl-6'>
          <Form onSubmit={handleSubmit} validate>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContact">
              <Form.Label>Contact</Form.Label>
              <Form.Control type="text" name="contact_number" placeholder="Enter your contact number" required value={formData.contact_number} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email_id" placeholder="Enter your email address" value={formData.email_id} onChange={handleChange} required  />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" placeholder="Enter your message" value={formData.description} onChange={handleChange} required />
            </Form.Group>

            <Button className='btn btnpink' type="submit">
              Submit
            </Button>
          </Form>
          </div>
          <div className='col-xxl-2 col-xl-2 col-lg-2 '></div>
          <div className='col-12  col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column gap-3 mt-4  '>
            <div className='d-flex align-items-start  gap-3' >
              <div className={style.address}>
                <FontAwesomeIcon icon={faMapLocation} className='fs-3' />
              </div>
              <div>
                <h4>
                  Address</h4>
                <span>11,pg click,opp. <br /> pg click,godra,gujarat</span>
              </div>
            </div>
            <div className='d-flex align-items-start gap-3' >
              <div className={style.address}>
                <FontAwesomeIcon icon={faPhone} className='fs-3' />
              </div>
              <div>
                <h4>
                  Phone</h4>
                <span>+91 123456789</span>
              </div>
            </div>
            <div className='d-flex align-items-start gap-3' >
              <div className={style.address}>
              <FontAwesomeIcon icon={faEnvelope}  className='fs-3'/>
              </div>
              <div>
                <h4>
                  Email</h4>
                <span>WkI1H@example.com</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactUsFooter;

