import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import style from '../SubcategoriesProductList/SubcategoriesProductList.module.css';
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function SubcategoriesProductList() {
    const { categoryId } = useParams();
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [bannerList, setBannerList] = useState([]);
    const [feedList, setFeedList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/api/sub-category-list/${categoryId}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.ResponseCode === 1) {
                    setSubcategoryList(data.ResponseData.subcategoryList);
                    setBannerList(data.ResponseData.categorywisebannerList);
                    setFeedList(data.ResponseData.feedList);
                } else {
                    console.error('Error fetching subcategory data:', data.ResponseText);
                }
            })
            .catch((error) => {
                console.error('Error fetching subcategory data:', error);
            });
    }, []);

    return (
        <>
            <Carousel>
                {bannerList.map((banner) => (
                    <Carousel.Item key={banner.id}>
                        <div className={style.BannerFlex}>
                            <img src={banner.imageurl} className={style.bannerImage} alt={banner.name}
                             onClick={() => navigate(`/productdetails/${banner.bannder_linkid}`)}   />
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <section>
                <Container fluid className='ps-xxl-5 pe-xxl-5'>
                    <Row className='mt-1 mt-xxl-5 mt-xl-5 mt-lg-5'>
                        <h3 className=' mb-3' >Browser By Category</h3>
                        {subcategoryList.map((subcategory) => (
                            <Col xxl={2} lg={2} md={3} xs={4} className=' paddingStyle' key={subcategory.id} data-aos="fade-up" data-aos-duration="2000">
                                <Link to={`/listing/${subcategory.id}`} key={subcategory.id}>
                                    <div>
                                        <div className={style.CategoryFlex}>
                                            <div className={style.categoryImgDiv}>
                                                <img src={subcategory.image} alt={subcategory.image} className={style.categoryImg}  />
                                            </div>
                                            <h6 className={style.categoryTitle}>{subcategory.subcategory_name.length > 15 ? subcategory.subcategory_name.slice(0, 15) + '...' : subcategory.subcategory_name}</h6>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='ps-xxl-5 pe-xxl-5'>
                    <Row className='mt-1 mt-xxl-5 mt-xl-5 mt-lg-5'>
                        {/* <h3 className=' mb-3' >Feed List</h3> */}
                        {feedList.map((feed) => (
                            <Col key={feed.id}>
                                <h5>{feed.fieldname}</h5>                              
                                {feed.products_list.map((product) => (
                                    <div key={product.id}>
                                        <img src={product.product_image} alt={product.name} />
                                        <p>{product.name}</p>                                      
                                    </div>
                                ))}
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section>
            <Container fluid className='ps-xxl-5 pe-xxl-5'>
    <Row className='mt-1 mt-xxl-5 mt-xl-5 mt-lg-5'>
        {feedList.map((feed) => (
            <Col key={feed.id}>
                <Carousel>
                    {feed.adbanners_list.map((banner) => (
                        <Carousel.Item key={banner.id}>
                            <div>
                                <img src={banner.image} alt={banner.name} />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Col>
        ))}
    </Row>
</Container>
            </section>
        </>
    );
}

export default SubcategoriesProductList;
