import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import style from './OurServices.module.css';
import arrow from '../../../images/rt-arrow.svg fill.png';
import { SERVICES } from './OurServices.utils';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1025 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  smalldeskpoint: {
    breakpoint: { max:1024, min:941 },
    items: 3,
    slidesToSlide: 3,
  },
  pc: {

    breakpoint: { max: 1226, min:941 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
    
  },
  tablet: {
    breakpoint: { max: 940, min: 622 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 621, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};


const OurServices = () => {
  return (
  <>
 <section >
  
<div className={style.Servicebg}>
<Container fluid className='ps-xxl-5 pe-xxl-5'>
<h4 className='mb-3 pt-5' data-aos="fade-up" data-aos-duration="1000"> Our services</h4>
 <Carousel 
 infinite={true}
 autoPlay={true}
 autoPlaySpeed={2000}
 responsive={responsive} >
          {SERVICES.map((item, index) => (
            <div key={index} data-aos="fade-up" data-aos-duration="2000">
              <div className={style.ServiceFlex}>
                <img className='img-fluid' style={{ width: '100%', height: '100%' }} src={item.image} alt="" />
                <span className={style.servicesText}>{item.title}</span>
                <span className={style.servicesTextt}>{item.subtitle}</span>
                <span className={style.servicesTexttt}>Learn more <img src={arrow} alt="" /></span>
              </div>
            </div>
          ))}
        </Carousel>
</Container>
</div>
 </section>
  </>
  )
}

export default OurServices
