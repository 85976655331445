import React from 'react'
import SubcategoriesProductList from './SubcategoriesProductList/SubcategoriesProductList'
import Header from '../../Common/Header/Header'
import OurServices from '../Home/OurServices/OurServices'
import FeaturedProducts2 from '../Home/FeaturedProducts2/FeaturedProducts'
import AdvertisementBanner from '../Home/AdvertisementBanner/AdvertisementBanner'
import FeatureProducts3 from '../Home/FeatureProducts3/FeatureProducts3'
import Testimonial from '../Home/Testimonial/Testimonial'
import UpcomingTradeshow from '../Home/UpcomingTradeshow/UpcomingTradeshow'
import FeaturedProduct from '../Home/FeaturedProduct/FeaturedProduct'
import BottomNavigationn from '../../Componenets/BottomNavigation/BottomNavigation'

function Subcategories() {
  return (
    <>
      <SubcategoriesProductList />
      <BottomNavigationn />
    </>
  )
}

export default Subcategories