import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import style from './Register.module.css'
import loginimag from '../../images/loginImage.png'
import grocery from '../../images/grocery-store.svg.png'
import phone from '../../images/smartphone1.svg.png';
import finger from '../../images/tap2.svg.png'

function Register() {
    return (
        <>

            <Container fluid className='ps-xxl-5 pe-xxl-5'  >
                <Row>
                    <Col lg={6} md={6} xl={6} xxl={6} data-aos="fade-up" data-aos-duration="2000" className='mt-5' >
                        <div className={style.loginimagee}>
                            <img src={loginimag} className={style.loginimage} />
                        </div>
                    </Col>
                    <Col lg={6} md={6} xl={6} xxl={6}>
                        <div className='mt-5 loginn' data-aos="fade-up" data-aos-duration="2000">
                            <h1 className={style.loginhead}>Login !</h1>
                            <form className='d-flex flex-column w-75  gap-4 registerInputt'>
                                {/* <span className={style.span}>Full Name</span> */}
                                <input type='text' placeholder='Full Name' className='px-4 py-2 border border-none rounded' />
                                <input type='text' placeholder='Enter Mobile No.' className='px-4 py-2  border border-none rounded' />
                                <input type='text' placeholder='Email id' className='px-4 py-2  border border-none rounded' />
                                <select name="" id="" className='form-select px-4 py-2 mb-5'>
                                    <option value="">Select City</option>
                                    <option value="">Surat</option>
                                    <option value="">Vadodara</option>
                                    <option value="">Ahemdabad</option>
                                </select>
                            </form>
                            <div className={style.submitbtn}>
                                <button type='submit' className='btnpink'>Submit</button>
                            </div>                    </div>
                    </Col>
                </Row>
            </Container>

        </>
    )

}


export default Register