import React from 'react'
import Header from '../../Common/Header/Header'
import List from './List/List'
import BottomNavigationn from '../../Componenets/BottomNavigation/BottomNavigation'

const Listing = () => {
    return (
        <>
            {/* <Header name={"Listings-list"} subname={"Listing"} /> */}
            <List/>
            <BottomNavigationn />
        </>
    )
}

export default Listing 
