import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function EditProduct() {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('details');
    const [key, setKey] = useState('home');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [show, setShow] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [specification, setSpecification] = useState([]);
    const [image, setImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [keyword, setKeyword] = useState([]);



    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/api/categorylist`);
                setCategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching categories: ', error);
            }
        };

        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/api/subcategory-list`);
                setSubcategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching subcategories: ', error);
            }
        };
        fetchCategories();
        fetchSubcategories();
    }, []);
    useEffect(() => {
        // Fetch data from the API
        fetch(`${process.env.REACT_APP_API}/api/unit-list`) 
            .then(response => response.json())
            .then(data => {
                // Update the state with the fetched data
                setUnitTypes(data.ResponseData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_API}/api/productedit/${id}`)
            .then(response => {
                const productData = response.data.ResponseData[0];
                setFormData({
                    name: productData.name,
                    category_id: productData.category_id,
                    subcategory_id: productData.subcategory_id,
                    product_code: productData.product_code,
                    price: productData.price,
                    unit_type: productData.unit_type,
                    discounted_price: productData.discounted_price,
                    discount: productData.discount ,
                    description: productData.description,
                    is_active: productData.is_active,
                    product_image: productData.product_image,
                });
            })
            .catch(error => console.error('Error fetching product data:', error));
    }, []);
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        product_code: '',
        subcategory_id: '',
        price: '',
        unit_type: '',
        discounted_price: '',
        discount: '',
        description: '',
        is_active: '',
        product_image: null,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, product_image: file });
    };

    const handleSaveAndNext = () => {
        // Array to store the names of missing fields
        const missingFields = [];
    
        // Validation logic to check if required fields are filled
        if (!formData.name) missingFields.push('Product Name');
        if (!formData.category_id) missingFields.push('Category');
        if (!formData.subcategory_id) missingFields.push('Sub Category');
        if (!formData.unit_type) missingFields.push('Unit Type');
        if (!formData.product_image) missingFields.push('Product Image');
    
        if (missingFields.length > 0) {
            // If any required field is missing, display an error message with field names
            const missingFieldsMessage = `Please fill in the following fields: ${missingFields.join(', image,')}.`;
            toast.error(missingFieldsMessage);
            return; // Stop execution if validation fails
        }
    
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('category_id', formData.category_id);
        formDataToSend.append('subcategory_id', formData.subcategory_id);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('unit_type', formData.unit_type);
        formDataToSend.append('discounted_price', formData.discounted_price);
        formDataToSend.append('discount', formData.discount);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('is_active', formData.is_active);
        formDataToSend.append('product_code', formData.product_code);
    
        if (formData.product_image) {
            formDataToSend.append('product_image', formData.product_image);
        }
    
        fetch(`${process.env.REACT_APP_API}/api/product-update/${id}`, {
            method: 'POST',
            body: formDataToSend,
        })
        .then(response => response.json())
        .then(data => {
            // Handle the response data as needed
            console.log(data);
            toast.success('Product updated successfully!');
            setActiveTab('specification');
            // Proceed to the next tab after successful submission
            setGeneralTabFilled(true);
        })
        .catch(error => {
            // Handle errors
            console.error('Error:', error);
            toast.error('Failed to update product. Please try again.');
        });
    };
    
    useEffect(() => {
        fetchKeyword();
        fetchImage();
        fetchSpecification();
    }, [id]);

    // Specifications //
    const fetchSpecification = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API}/api/product-variant-list/${id}`);
          setSpecification(response.data.ResponseData);
        } catch (error) {
          console.error("Error fetching product specifications: ", error);
        }
      }
    const [showProductLinkTable, setShowProductLinkTable] = useState(false);
    const handleShowProductLinkTable = () => {
        setShowProductLinkTable(true);
    };
    const handleCloseProductLinkTable = () => setShowProductLinkTable(false);
    const handleSpecification = async () => {
        try {
            const form = new FormData();
            form.append('product_id', id);
            form.append('variant_name', formData.variant_name);
            form.append('variant', formData.variant);
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/add-validator`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                console.log('Product image added successfully:', response.data.ResponseData);
                toast.success('Product Specification Added successfully!');
                fetchSpecification();
            } else {
                console.error('Error adding product image:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };

    const handleDeleteVariant = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/validator-delete/${id}`,
            );
            if (response.data.ResponseCode === 1) {
                console.log('Variant deleted successfully:', response.data.ResponseText);
                toast.success('Product Specification deleted successfully!');
                setSpecification((prevVariants) => prevVariants.filter((variant) => variant.id !== id));
                setGeneralTabFilled(true);
            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };

    // Image upload  //
    const [showImageTable, setShowImageTable] = useState(false);

    // Fetch image data
    const fetchImage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/product-image-list/${id}`);
            setImage(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product images: ", error);
        }
    }
    const handleShowImageTable = () => {
        setShowImageTable(true);
    };
    const handleImageUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', id);
            form.append('image_url', selectedImage);
            form.append('status', 1);
            form.append('comment', 'good');
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/add-image`,
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Product image added successfully!');
                fetchImage();
            } else {
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handledeleteImage = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/image-delete/${id}`
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Image deleted successfully');
                setImage((prevImages) => prevImages.filter((image) => image.id !== id));
                toast.success('Product image deleted successfully!');
            } else {
                toast.error('Error deleting image');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseImageTable = () => setShowImageTable(false);

    // Keyword //
    const [showKeywordTable, setShowKeywordTable] = useState(false);

    const handleShowKeywordTable = () => {
        setShowKeywordTable(true);
    };
    const handleCloseKeywordTable = () => setShowKeywordTable(false);

    const fetchKeyword = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/product-keyword-list/${id}`);
            setKeyword(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product keywords: ", error);
        }
    };

    const handleKeywordUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', id);
            form.append('keyword', formData.keyword);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/add-keyworld`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Product Keyword added successfully!');
                fetchKeyword();
            } else {
                toast.error('Failed to add product. Please try again.');
                console.error('Error adding product image:', response.data.ResponseText);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        }
    };
    const handleDeleteKeyword = async (productskeyword_id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/delete-keyworld/${productskeyword_id}`,
            );

            if (response.data.ResponseCode === 1) {
                setKeyword((prevKeywords) => prevKeywords.filter((keyword) => keyword.productskeyword_id !== productskeyword_id));
                setGeneralTabFilled(true);
                toast.success('Product Keyword deleted successfully!');
            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    return (
        <>
            <section className="content " >
                <Container fluid className='ps-0'>
                    <div className="body_scroll row" id='product' >
                        <div className="block-header">
                        </div>
                        <div>
                            <div className="row clearfix">
                                <div className='col-3 col-lg-2 mt-1'>
                                    <SideBar />
                                    </div>
                                <div className="col-9 col-lg-10 mt-1">
                                    <div className="pagetitle  mb-2 mt-3">
                                        <nav className='d-flex  justify-content-between'>
                                            <div>
                                                <h4>Edit Product</h4>
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                                    <li className="breadcrumb-item">Product</li>
                                                </ol>
                                            </div>
                                            <div className="d-flex align-items-center gap-1">
                                                <Button
                                                    type="submit"
                                                    data-form="form-product"
                                                    data-bs-toggle="tooltip"
                                                    title class="btn btn-primary"
                                                    data-bs-original-title="Save"
                                                    onClick={() => {
                                                        localStorage.removeItem('product_id');
                                                        window.location.href = "/productlist";
                                                    }}>
                                                    <i class="bi bi-floppy2" />
                                                </Button>
                                                <a href="/viewgeneral" data-bs-toggle="tooltip" title class="btn btn-secondary" data-bs-original-title="Back" aria-label="Back">
                                                    <i class="bi bi-reply-fill" />
                                                </a>
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="card">
                                        <div className="body p-xxl-5 p-xl-5 p-lg-5 ">
                                            <Tabs id="controlled-tab-example"
                                                activeKey={activeTab}
                                                onSelect={(k) => setActiveTab(k)}
                                                className="mb-3" >
                                                <Tab eventKey="details" title={<span> Details</span>} onSelect={() => setGeneralTabFilled(false)}>
                                                   
                                                    <div className='productmargin d-flex flex-column m-3 pt-3'  >
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3' >
                                                            <Col xs={10}   md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap">Product Name :-</label>
                                                                <input type="text" className="form-control "
                                                                    name='name' value={formData.name}
                                                                    onChange={handleInputChange}  />
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label">Category :-</label>
                                                                <select name="category_id" id="templateId" value={formData.category_id} class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} >
                                                                    <option >
                                                                        --select category--
                                                                    </option>
                                                                    {categoryData &&
                                                                        categoryData.map((v, index) => {
                                                                            return (
                                                                                <option value={v.id} key={index.id}>
                                                                                    {v.category_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap ">Sub Category :-</label>
                                                                <select name="subcategory_id" id="templateId" value={formData.subcategory_id} class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} >
                                                                    <option value="">
                                                                        --select category--
                                                                    </option>
                                                                    {subcategoryData &&
                                                                        subcategoryData.map((v, index) => {
                                                                            return (
                                                                                <option value={v.id} key={index.id}>
                                                                                    {v.subcategory_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label ">ProductCode :-</label>
                                                                <input type="number" className="form-control" value={formData.product_code}  name='product_code' onChange={handleInputChange}  />
                                                            </Col>
                                                            <Col  xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap ">unit:-</label>
                                                                <select
                                                                    name="unit_type"
                                                                    id="templateId"
                                                                    className="form-control show-tick ms select2"
                                                                    data-placeholder="Select"
                                                                    value={formData.unit_type}
                                                                    onChange={handleInputChange}

                                                                     >
                                                                    <option value=" ">--select unit--</option>
                                                                    {unitTypes.map((unit, index) => (
                                                                        <option key={index} value={unit.unit_type_id}>
                                                                            {unit.unit_type}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label ">Price :-</label>
                                                                <input type="number" className="form-control" name='price' value={formData.price} onChange={handleInputChange}  />
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5} >
                                                                <label className="form-label text-nowrap me-2">Discount Price :-</label>
                                                                <input type="" className="form-control" name='discounted_price' value={formData.discounted_price} onChange={handleInputChange}  />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label ">Discount :-</label>
                                                                <input type="number" className="form-control" name='discount' value={formData.discount} onChange={handleInputChange}  />
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap ">Is Active :-</label>
                                                                <select
                                                                    name="is_active"
                                                                    id="templateId"
                                                                    className="form-control show-tick ms select2"
                                                                    data-placeholder="Select"
                                                                    onChange={handleInputChange}
                                                                    value={formData.is_active}
                                                                     >
                                                                    <option >select Status</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label">Short Discription :-</label>
                                                                <textarea type="text" className="form-control" name='description' value={formData.description} onChange={handleInputChange}  />
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label">Select Image :-</label>
                                                                <input type="file" className="form-control" name='product_image' onChange={handleFileChange}  />
                                                                {formData.product_image && (
                                                                    <img src={formData.product_image} alt="Product Image" className="img-fluid w-25 " />
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='d-flex  justify-content-end mt-3 me-3'>
                                                        <button className="  btn btn-primary   ps-4 pe-4 "
                                                            onClick={() => {
                                                                handleSaveAndNext();
                                                                setGeneralTabFilled(true);
                                                            }}  >Save & Next</button>
                                                    </div>
                                                    
                                                </Tab>
                                                <Tab eventKey="specification" title="Specification" disabled={!generalTabFilled}
                                                    onSelect={(k) => !generalTabFilled && setActiveTab('details')}>
                                                    <div>
                                                        <div className="row clearfix">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-title"> Specification</h5>
                                                                        <Button className="btngray1 addapc"
                                                                            onClick={handleShowProductLinkTable}
                                                                        >Add Specification</Button>
                                                                        {/* modal */}
                                                                        <Modal show={showProductLinkTable} onHide={handleCloseProductLinkTable}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title className='text-center w-100' >Specification</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3" controlId="attributeId">
                                                                                        <div className="d-flex justify-content-between">
                                                                                            <Form.Label className='me-3 '>Specification:-</Form.Label>
                                                                                            <input type="text" name='variant_name' className='form-control' 
                                                                                            onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })}
                                                                                             />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    <Form.Group
                                                                                        controlId="attributeText"
                                                                                    >
                                                                                        <div className="d-flex  justify-content-between">

                                                                                            <Form.Label className='me-5'>variant:-</Form.Label>
                                                                                            <Form.Control as="input"
                                                                                                type="text" name='variant' 
                                                                                                onChange={(e) => setFormData({ ...formData, variant: e.target.value })}
                                                                                                />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Modal.Body>
                                                                            <Modal.Footer className='d-flex justify-content-center'>
                                                                                <Button
                                                                                     onClick={() => {
                                                                                        handleSpecification();
                                                                                        handleCloseProductLinkTable();
                                                                                    }}
                                                                                    className='btngray px-5'>
                                                                                    Save
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </div>

                                                                    <div className="m-2">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">NO</th>
                                                                                        <th scope="col">Specification</th>
                                                                                        <th scope="col">Text</th>
                                                                                        <th scope="col" style={{ marginLeft: "200px" }}>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                        {specification.map((variant, index) => (
                                                                                    <tr>
                                                                                        <td scope="col">{index + 1}</td>
                                                                                        <td scope="col">{variant.variant_name}</td>
                                                                                        <td scope="col">{variant.variant}</td>
                                                                                        <td scope="col" style={{ marginLeft: "200px" }}>
                                                                                            <button className="btn btn-danger ms-2 " onClick={() => handleDeleteVariant(variant.id)}
                                                                                            > <i class="bi bi-trash-fill me-1"></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                             

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="image" title="Image"
                                                    disabled={!generalTabFilled}
                                                    onSelect={(k) => !generalTabFilled && setActiveTab('details')}  >
                                                    <div >
                                                        <div className="row clearfix">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-title"> Image</h5>
                                                                        <Button className="btngray1"
                                                                            onClick={handleShowImageTable}
                                                                        >Add Image</Button>
                                                                        {/* modal */}
                                                                        <Modal
                                                                            size="md"
                                                                            show={showImageTable}
                                                                            onHide={handleCloseImageTable}
                                                                            aria-labelledby="example-modal-sizes-title-sm"
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title id="example-modal-sizes-title-sm">
                                                                                    Upload  Image
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <img src="" alt="" style={{ maxWidth: '100%' }} />
                                                                                {/* <input type="file" /> */}
                                                                                <input type="file" name="image_url" id="image_url"
                                                                                  onChange={handleImageChange}

                                                                                />
                                                                                <Button
                                                                                   onClick={() => { handleImageUpload(); handleCloseImageTable(); }}
                                                                                    className="m-2 btngray px-3">
                                                                                    Upload
                                                                                </Button>
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                    </div>
                                                                    <div className="m-2">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">NO</th>
                                                                                        <th scope="col">Image</th>
                                                                                        <th scope="col">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {image.map((image, index) => (
                                                                                    <tr key={index}>
                                                                                        <td scope="col">{index + 1}</td>
                                                                                        <td scope="col">
                                                                                            <img src={image.image_url} alt="" style={{ maxWidth: '100px' }} />
                                                                                        </td>
                                                                                        <td >
                                                                                            <button className="btn btn-danger ms-2 " onClick={() => handledeleteImage(image.id)}> <i class="bi bi-trash-fill me-1"></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                    {/* <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                        <Button className="btngray  ps-4 pe-4 " onClick={() => {
                                                                            // handleSave();
                                                                            setGeneralTabFilled(true);
                                                                        }}  >Save & Next</Button>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="keyword" title="Keyword"
                                                    disabled={!generalTabFilled}
                                                    onSelect={(k) => !generalTabFilled && setActiveTab('details')} >
                                                    <div >
                                                        <div className="row clearfix">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-title">Keyword</h5>
                                                                        <Button className="btngray1"
                                                                            onClick={handleShowKeywordTable}
                                                                        >Add Keyword</Button>
                                                                        {/* modal */}
                                                                        <Modal
                                                                            size="md"
                                                                            show={showKeywordTable}
                                                                            onHide={handleCloseKeywordTable}
                                                                            aria-labelledby="example-modal-sizes-title-sm"
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title id="example-modal-sizes-title-sm">
                                                                                    Enter keyword
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                            <input type="text" name='keyword' placeholder='Enter keyword ' className='form-control' onChange={(e) => setFormData({ ...formData, keyword: e.target.value })} />
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        handleKeywordUpload();
                                                                                        handleCloseKeywordTable();
                                                                                    }}
                                                                                    className="mt-3 px-5 btngray ">
                                                                                    Save
                                                                                </Button>
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                    </div>
                                                                    <div className="m-2">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">NO</th>
                                                                                        <th scope="col">Keyword</th>
                                                                                        <th scope="col">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                {keyword.map((keyword, index) => (
                                                                                    <tr>
                                                                                        <td scope="col">{index + 1}</td>
                                                                                        <td scope="col">{keyword.keyword}</td>
                                                                                        <td scope="col">
                                                                                            <button className="btn btn-danger ms-2 " onClick={() => handleDeleteKeyword(keyword.productskeyword_id)}
                                                                                            > <i class="bi bi-trash-fill me-1"></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>                                                                                                                                                                                       <div className='d-flex  justify-content-end mt-4 me-3'>
                                                                        {/* <Button className="  btngray ps-4 pe-4 " onClick={() => {
                                                                            handleSave();
                                                                            setGeneralTabFilled(true);
                                                                        }}  >Submit</Button> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <ToastContainer />
        </>
    )
}

export default EditProduct