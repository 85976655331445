import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import Categories from './pages/Categories/Categories';
import Listing from './pages/Listing/Listing ';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Otp from './pages/Otp/Otp';
import Dashboard from './pages/Dashboard/Dashboard';
import NAvbar from './Componenets/Layout/Navbar/NAvbar';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import Product from './pages/Product/Product';
import BuyLeads from './pages/BuyLeads/BuyLeads';
import Inquiry from './pages/Inquiry/Inquiry';
import ProductView from './pages/ProductView/ProductView';
import DashboardProductList from './pages/DashboardProductList/DashboardProductList';
import Phone from './pages/Phone';
import Subcategories from './pages/Subcategories/Subcategories';
import BusinessDetails from './pages/BusinessDetails/BusinessDetails';
import PricingPlanProfile from './pages/PricingPlanProfile/PricingPlanProfile';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import EditProduct from './pages/EditProduct/EditProduct';
import 'react-toastify/dist/ReactToastify.css';
import ProductList from './pages/ProductView/ProductList';
import Backgroundanimation from './pages/Backgroundanimation';
import LeaderBoard from './pages/LeaderBoard/LeaderBoard';
import Contact from './pages/Contact/Contact';
import ImagePicker from './pages/ImagePicker/ImagePicker';
import UnderConstruction from './pages/UnderConstruction/UnderConstruction';
import Footer from './Componenets/Layout/Footer/Footer';
import { DeletUserAccount } from './pages/DeletUserAccount';
import { Bottomprofile } from './pages/Bottomprofile';
import { Search } from './pages/Search';
import Video from './pages/Video/Video';
import SocialMedia from './pages/SocialMedia/SocialMedia';
import { BusinessWebsite } from './pages/BusinessWebsite';
import AboutCompany from './pages/AboutCompany/AboutCompany';
import LeaderBusinessDetail from './pages/LeaderBusinessDetail/LeaderBusinessDetail';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import { PostYourRequirement } from './pages/PostYourRequirement';
import UserProfileContact from './pages/UserProfileContact/UserProfileContact';
import UserDashboardProfile from './pages/UserDashboardProfile/UserDashboardProfile';
import ComingSoon from './pages/ComingSoon/ComingSoon';
import { OfferZone } from './pages/OfferZone';
import BusinessMetting from './pages/BusineeMeeting/BusinessMetting';
import EventExhibition from './pages/EventExhibition/EventExhibition';
import ContactUsFooter from './pages/FooterPages/ContactUsFooter/ContactUsFooter';
import { HelpCenter } from './pages/FooterPages/HelpCenter';
import { ReturnCancellationpolicy } from './pages/FooterPages/Return&Cancellationpolicy';
import { Deliverypolicy } from './pages/FooterPages/Deliverypolicy';
import { AboutOurCompany } from './pages/FooterPages/AboutOurCompanyFooter';
import { SuccessStories } from './pages/FooterPages/Success Stories';
import { PostYourRequirementfooter } from './pages/FooterPages/Post Your RequirementFooter';
import { Browser } from './pages/FooterPages/Browse SuppliersFooter';
import { SubscribeTrade } from './pages/FooterPages/Subscribe sell Trade AlertsFooter';


function App() {
  const location = useLocation();
 
      return (
        <>
          <NAvbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/categories' element={<Categories />} />
            <Route path='/listing/:subcategoryId' element={<Listing />} />
            <Route path='/productview/:ProductListId' element={<ProductView />} />
            <Route path='/productdetails/:ProductListId' element={<ProductDetails />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/otp' element={<Otp />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/termsConditions' element={<TermsConditions />} />
            <Route path='/product' element={<Product />} />
            <Route path='/buyleads' element={<BuyLeads />} />
            <Route path='/inquiry' element={<Inquiry />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/productlist' element={<DashboardProductList />} />
            <Route path='/phone' element={<Phone />} />
            <Route path='/subcategories/:categoryId' element={<Subcategories />} />
            <Route path='/businessdetails' element={<BusinessDetails />} />
            <Route path='/pricingplanprofile' element={<PricingPlanProfile />} />
            <Route path='/profilepage' element={<ProfilePage />} />
            <Route path='/editproduct/:id' element={<EditProduct />} />
            <Route path='/productlists' element={<ProductList />} />
            <Route path='/leaderboardd' element={<LeaderBoard />} />
            <Route path='/imagepicker' element={<ImagePicker />} />
            <Route path='/delet_user_account' element={<DeletUserAccount />} />
            <Route path='/userprofile' element={<Bottomprofile />} />
            <Route path='/search' element={<Search />} />
            <Route path='/video' element={<Video />} />
            <Route path='/socialmedia' element={<SocialMedia />} />
            <Route path='/businesswebsite' element={<BusinessWebsite />} />
            <Route path='/aboutcompany' element={<AboutCompany />} />
            <Route path='/leaderbusinessdetail' element={<LeaderBusinessDetail/>} />
            <Route path='/userdashboard' element={<UserDashboard />} />
            <Route path='/postyourrequirement' element={<PostYourRequirement/>} />
            <Route path='/userprofilecontact' element={<UserProfileContact />} />
            <Route path='/userdashboardprofile' element={<UserDashboardProfile />} />
            <Route path='/comingsoon' element={<ComingSoon />} />
            <Route path='/offerzone' element={<OfferZone />} />
            <Route path='/busineemeeting' element={<BusinessMetting />} />
            <Route path='/eventexhibition' element={<EventExhibition />} />
            <Route path='/contactus' element={<ContactUsFooter/>} />
            <Route path='/helpcenter' element={<HelpCenter />} />
            <Route path='/return&cancellationpolicy' element={<ReturnCancellationpolicy />} />
            <Route path='/deliverypolicy' element={<Deliverypolicy />} />
            <Route path='/aboutourcompany' element={<AboutOurCompany />} />
            <Route path='/successstories' element={<SuccessStories />} />
            <Route path='/post_your_requirement' element={<PostYourRequirementfooter />} />
            <Route path='/browse_suppliers' element={<Browser />} />
            <Route path='/subscribe_trade' element={<SubscribeTrade/>} />
          </Routes>
          <Footer />
        </>
      );
    }

export default App;
