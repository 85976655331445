import React, { useState, useEffect } from 'react';
import { Container, Alert, Button } from 'react-bootstrap'; // Import Alert component for displaying messages
import SideBar from '../../Common/SideBar/SideBar';
import ReactImagePickerEditor from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import style from './ImagePicker.module.css';

const ImagePicker = () => {
    const [companyId] = useState(localStorage.getItem('clientId'));
    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadLimitReached, setUploadLimitReached] = useState(false); 
    // State variable to track upload limit reached

    const config = {
        borderRadius: '8px',
        language: 'en',
        width: '250px',
        height: '250px',
        objectFit: 'contain',
        compressInitial: null,
    };

    useEffect(() => {
        fetchClientImages();
    }, []);

    const fetchClientImages = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clients-officeimag-list/${companyId}`);
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Client office images fetched successfully');
                setUploadedImages(data.ResponseData);
            } else {
                console.error('Error fetching client images:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching client images:', error);
        }
    };

    const uploadImage = async (imageDataUri) => {
        try {
            const blob = await fetch(imageDataUri).then((res) => res.blob());
            const formData = new FormData();
            formData.append('company_id', companyId);
            formData.append('image_url', blob, 'image.jpg');

            const response = await fetch(`${process.env.REACT_APP_API}/api/add-officeimage`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Image uploaded successfully');
                fetchClientImages();
                if (uploadedImages.length === 4) {
                    setUploadLimitReached(true); // Set upload limit reached message when 5 images are uploaded
                }
            } else {
                console.error('Error uploading image:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleImageChange = (newDataUri) => {
        if (newDataUri && uploadedImages.length < 5) { 
            uploadImage(newDataUri);
        } else if (newDataUri && uploadedImages.length >= 5) {
            setUploadLimitReached(true);
        }
    };
    const deleteImage = async (clientimage_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/deleteofficeimage/${clientimage_id}`, {
                method: 'POST',
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Image deleted successfully');
                fetchClientImages(); // Fetch updated list of images after deletion
            } else {
                console.error('Error deleting image:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    return (
        <Container fluid className='ps-0'>
        <div className='row gap-xxl-5'>
            <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                <SideBar />
            </div>
            <div className='col-8 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
            <div className='text-end mb-3 mt-3'>
                <Link to={'/dashboard'}>
                  <Button className='btnpink px-5'>Back</Button>
                </Link>
              </div>
                <h2 className='mt-1 mt-xxl-5 mt-xl-5 mt-lg-5 text-uppercase'>Gallery</h2>
                <h4 style={{ color: 'var(--pink)', marginTop: '20px', marginBottom: '40px' }}>Upload Images <span>(only 5 images you can upload)</span></h4>
                {uploadLimitReached && <Alert variant="warning">You cannot upload more than 5 images.</Alert>}
                <div className='d-flex flex-wrap gap-3'>
                    {uploadedImages.map((image, index) => (
                      <div key={index} className="position-relative">
                      <img src={image.image_url} alt={`Image ${index}`}
                      className={style.imagepic} 
                       />
                      <button className="btn  position-absolute top-0 end-0" onClick={() => deleteImage(image.clientimage_id)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                  </div>
                    ))}
                    <div>
                        <ReactImagePickerEditor
                            config={config}
                            imageSrcProp=''
                            imageChanged={(newDataUri) => handleImageChange(newDataUri)}
                        />
                        {uploadLimitReached && <p>You cannot upload more than 5 images.</p>}
                    </div>
                </div>
            </div>
        </div>
    </Container>
    );
};

export default ImagePicker;
