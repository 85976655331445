import React, { useState, useEffect } from 'react';
import team1 from '../../../images/caresouel-1.png';
import team2 from '../../../images/caresouel-2.png';
import team3 from '../../../images/caresouel-3.png';
import quoteleft from '../../../images/quote-down.svg.png';
import quoteright from '../../../images/quote-1.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Container } from 'react-bootstrap';
import style from './Testimonial.module.css'



const Testimonial = () => {
  const [feedbackData, setFeedbackData] = useState([]);

  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/home`); // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        const data = await response.json();
        setFeedbackData(data.ResponseData.feedbackList);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      }
    };

    fetchFeedbackData();
  }, []);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section >
       

        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <div className={style.Testimonial}>
          <div className='text-center mt-50 mb-20 '>
          <h3 className={style.TestimonialTitle}>What Our Customers Say About Us</h3>
          <h6 className={style.paraa}>Don't just take our word for it. Our customers say it the best.</h6>
        </div>
          <Carousel
            infinite={true}
            autoPlay={true}
            showDots={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            responsive={responsive}
            removeArrowOnDeviceType={['tablet', 'mobile']} >
            {feedbackData.map((item) => (
              <div key={item.id} className='d-flex justify-content-between align-items-center flex-column '>
                <div className=' testiimage'>
                  {/* <img className='rounded-circle team3 ' src={team3} alt="" /> */}
                  <img className={style.clientsimage} src={item.image} alt={`Image for ${item.clients_name}`} />
                  {/* <img className='rounded-circle team2' src={team2} alt="" /> */}
                </div>
                <div className='text-center'>
                  <span>{item.clients_name}</span>
                  <p>{item.designation}, {item.company_name}</p>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-2 '>
                  <img src={quoteright} alt="" className='quoteright' style={{ alignSelf: 'flex-start', marginBottom: '50px' }} />
                  <p className='testpara' style={{ marginBottom: '20px',padding:'25px' }}>{item.feedback}</p>
                  <img src={quoteleft} alt="" className='quoteleft' style={{ alignSelf: 'flex-end' }} />
                </div>
              </div>
            ))}
           

          </Carousel>
          </div>
        </Container>

      </section>
    </>
  )
}

export default Testimonial
