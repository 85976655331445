import React, { useState, useEffect } from 'react'
import SideBar from '../../Common/SideBar/SideBar'
import { Button, Container, Tab } from 'react-bootstrap'
import style from './DashboardProductList.module.css'
import { Tabs } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
const DashboardProductList = () => {
  const [products, setProducts] = useState([]);
  const [progressProducts, setprogressProducts] = useState([]);
  const [rejectedProducts, setrejectedProducts] = useState([]);
  const [activeTab, setActiveTab] = useState('Active');
  const navigate = useNavigate();


  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API}/api/product-list-userwise/${localStorage.getItem('userId')}`;
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        setProducts(data.ResponseData.activeproduct);
        setprogressProducts(data.ResponseData['in-progressproduct'])
        setrejectedProducts(data.ResponseData.rejectedproduct)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const handleDeleteKeyword = async (id, tab) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/product-delete/${id}`,
      );
      if (response.data.ResponseCode === 1) {
        switch (tab) {
          case 'Active':
            setProducts((prevProduct) => prevProduct.filter((product) => product.id !== id));
            toast.success('Active Product deleted successfully!');
            break;
          case 'in-Progress':
            setprogressProducts((prevProduct) => prevProduct.filter((product) => product.id !== id));
            toast.success('In Progress Product deleted successfully!');
            break;
          case 'rejected':
            setrejectedProducts((prevProduct) => prevProduct.filter((product) => product.id !== id));
            toast.success('Rejected Product deleted successfully!');
            break;
          default:
            break;
        }
      } else {
        console.error('Error deleting variant:', response.data.ResponseText);
        toast.error(response.data.ResponseText);
      }
    } catch (error) {
      console.error('API request failed:', error.message);
      toast.error('API request failed:', error.message);
    }
  };
  return (
    <section>
      <Container fluid className='ps-0'>
        <div className='row gap-xxl-5' id='productlist'>
          <div className='col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
            <SideBar />
          </div>
          <div className='col-8 col-sm-9 col-md-9 col-lg-10 col-xl-10 col-xxl-9 mt-1 mt-xxl-5 mt-xl-5 mt-lg-5'>
            <div>
              <div className='text-end mb-3'>
                <Button className='btnpink px-xxl-5 px-xl-5 px-lg-5'>
                  <a href="product" className='text-white'>
                    Add New Product
                  </a>
                </Button>
              </div>
              <Tabs id='controlled-tab-example'
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
              >
                <Tab eventKey="Active" title={`Active (${products.length})`}>
                  {products.map((product) => (
                    <div key={product.id} className='d-flex gap-3 border border-1 p-3 bg-white'>
                      <div className={style.dashboardproductimg}>
                        <img src={product.product_image} alt={product.name} />
                      </div>
                      <div className='d-flex flex-column justify-content-between '>
                        <h3 className={style.headProduct}>
                          {product.name.length > 20 ? product.name.slice(0, 20) + '...' : product.name}
                        </h3>
                        <span>
                          {product.price && product.price !== "00" ? `₹ ${product.price}/` : 'Ask Price'}
                          {product.unit_type && product.price && product.price !== "00" ? ` ${product.unit_type}` : ''}
                        </span>
                        {/* <p className={style.productpara}>{product.description}</p> */}
                        <div className='d-flex align-items-center gap-3'>
                          <span className='fs-6 d-none d-md-block d-lg-block d-xl-block d-xxl-block'>Category :-</span>
                          <span className={style.categorydisc}>{product.subcategory_name}</span>
                        </div>
                        <div className='d-flex align-items-center gap-3 '>
                          <Button className='btnpink px-xxl-5 px-md-4 px-sm-4 px-3 productedit' onClick={() => navigate(`/editproduct/${product.id}`)} >Edit</Button>
                          <Button className='btnpink px-xxl-5 px-md-4 px-sm-4 px-3 productedit' onClick={() => handleDeleteKeyword(product.id, 'Active')}>Delete</Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Tab>
                <Tab eventKey="in-Progress" title={`In-Progress (${progressProducts.length})`}  >
                  {progressProducts.map((product) => (
                    <div key={product.id} className='d-flex gap-3 border border-1 p-3 bg-white '>
                      <div className={style.dashboardproductimg}>
                        <img src={product.product_image} alt={product.name} />
                      </div>
                      <div className='d-flex flex-column  justify-content-between'>
                        <h3 className={style.headProduct}>
                          {product.name.length > 20 ? product.name.slice(0, 20) + '...' : product.name}
                        </h3>
                        <span>
                          {product.price && product.price !== "00" ? `₹ ${product.price}/` : 'Ask Price'}
                          {product.unit_type && product.price && product.price !== "00" ? ` ${product.unit_type}` : ''}
                        </span>
                        <div className='d-flex align-items-center gap-3'>
                          <span className='fs-6 d-none d-md-block d-lg-block d-xl-block d-xxl-block'>Category :-</span>
                          <span  className={style.categorydisc}>{product.subcategory_name}</span>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                          <Button className='btnpink px-xxl-5 px-md-4 px-sm-4 px-3 productedit' onClick={() => navigate(`/editproduct/${product.id}`)} >Edit</Button>
                          <Button className='btnpink px-xxl-5 px-md-4 px-sm-4 px-3 productedit' onClick={() => handleDeleteKeyword(product.id, 'in-Progress')}>Delete</Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Tab>
                <Tab eventKey="rejected" title={`Rejected (${rejectedProducts.length})`}>
                  {rejectedProducts.map((product) => (
                    <div key={product.id} className='d-flex gap-3 border border-1 p-3 bg-white'>
                      <div className={style.dashboardproductimg}>
                        <img src={product.product_image} alt={product.name} />
                      </div>
                      <div className='d-flex flex-column  justify-content-between '>
                      <h3 className={style.headProduct}>
                          {product.name.length > 20 ? product.name.slice(0, 20) + '...' : product.name}
                        </h3>
                        <span>
                          {product.price && product.price !== "00" ? `₹ ${product.price}/` : 'Ask Price'}
                          {product.unit_type && product.price && product.price !== "00" ? ` ${product.unit_type}` : ''}
                        </span>
                              <div className='d-flex align-items-center gap-3'>
                          <span className='fs-6 d-none d-md-block d-lg-block d-xl-block d-xxl-block'>Category :-</span>
                          <span className={style.categorydisc}>{product.subcategory_name}</span>
                        </div>
                        <div className='d-flex align-items-center gap-3 '>
                          <Button className='btnpink px-xxl-5 px-md-4 px-sm-4 px-3 productedit' onClick={() => navigate(`/editproduct/${product.id}`)}  >Edit</Button>
                          <Button className='btnpink px-xxl-5 px-md-4 px-sm-4 px-3 productedit' onClick={() => handleDeleteKeyword(product.id, 'rejected')}>Delete</Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </section>
  )
}
export default DashboardProductList