import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteUserAccount = () => {
    const [showModal, setShowModal] = useState(false);
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [error, setError] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        setShowConfirmation(false);
    };

    const handleShowModal = () => setShowModal(true);

    const handleDeleteAccount = () => {
        if (!emailOrPhone.trim()) {
            setError('Please fill in the email or phone number field.');
        } else {
            setError('');
            handleShowModal();
        }
    };

    const handleConfirmDeletion = () => {
        setShowModal(false);
        setShowConfirmation(true);    };

    return (
        <>
            <section>
                <div className="bg-body-tertiary">
                    <nav className="navbar navbar-expand-lg container">
                        <div className="container-fluid">
                            {/* <a className="navbar-brand fw-bold fs-4" style={{ color: '#36a4c3', lineHeight: '40px' }} href="#">Wallpaper studio</a> */}
                            <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </nav>
                </div>
                <div className="container p-5 mt-5 rounded">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-6" style={{ border: '2px solid #EFEFEF', padding: '30px 30px', borderRadius: '10px', marginTop: '100px' }}>
                            <form id="deleteAccountForm">
                                <h1 className="maintitle mb-4">Delete User Account</h1>
                                <div className="d-flex flex-column">
                                    <input type="text" className="p-3" placeholder="Enter Your Phone/email" value={emailOrPhone} onChange={(e) => setEmailOrPhone(e.target.value)} required />
                                    <div className="d-flex justify-content-center mt-3">
                                        <button type="button" className="btn btnpink px-3 text-white mt-3" onClick={handleDeleteAccount}>Delete Account</button>
                                    </div>
                                    {error && <p className="text-danger">{error}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete your account?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>No</Button>
                    <Button variant="primary" onClick={handleConfirmDeletion}>Yes</Button>
                </Modal.Footer>
            </Modal>
            {showConfirmation && (
                <div className="container-fluid  p-5 mt-3 rounded" >
                    <p>Admin will delete your account within 24 hours.</p>
                </div>
            )}
        </>
    )
}

export default DeleteUserAccount;
