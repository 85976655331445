import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';

const ProfilePage = () => {
  const user = {
    name: 'John Doe',
    email: 'john@example.com',
    phone: '123-456-7890',
    address: '123 Main Street, Cityville',
    profilePicture: 'path/to/profile-pic.jpg', // Replace with the actual path or URL
    // Add more user details as needed
  };
  const company = {
    name: 'ABC Corporation',
    industry: 'Technology',
    website: 'http://www.abc-corp.com',
    // Add more company details as needed
  };
  return (
    <>
      <Container fluid className=" ps-0">
        <div id='profile' className='row gap-xxl-5'>
          <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
            <SideBar />
          </div>
          <div className='col-8 col-sm-8 col-md-9 col-lg-10 col-xl-10 col-xxl-9 mt-5'>
            <Card>
              <Card.Body>
                <Card.Title>Seller Information</Card.Title>
                <Card.Text>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div>
                        <img
                          src="path/to/profile-pic.jpg"
                          alt="Profile"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div>
                      </div>
                      <br />
                    </div>
                    <label htmlFor="name">Name:</label>
                    <input type="text" value={user.name} className='w-50 px-3 py-1' />
                    <br />
                    <label htmlFor="email">Email:</label>
                    <input type="email" value={user.email} className='w-50 px-3 py-1' />
                    <br />
                    <label htmlFor="phone">Phone:</label>
                    <input type="tel" value={user.phone} className='w-50 px-3 py-1' />
                    <br />
                    <label htmlFor="address">Address:</label>
                    <input type="text" value={user.address} className='w-50 px-3 py-1' />
                    <br />

                    <label htmlFor="company">Company:</label>
                    <input type="text" value={company.name} className='w-50 px-3 py-1' />
                    <br />
                    <label htmlFor="industry">Industry:</label>
                    <input type="text" value={company.industry} className='w-50 px-3 py-1' />
                    <br />
                    <label htmlFor="website">Website:</label>
                    <input type="url" value={company.website} className='w-50 px-3 py-1' />
                  </div>
                 
                </Card.Text>

                <Button variant="primary" className='btnpink'>Edit Profile</Button>
              </Card.Body>
            </Card>
          </div>
        </div>


      </Container>
    </>
  )
}

export default ProfilePage
