import React from 'react'
import { Col, Row } from 'react-bootstrap';
import style from './Footer.module.css';
import qrcode from '../../../images/QR-scanner.jpg.png';
import google from '../../../images/Bitmap.png';
import play from '../../../images/Bitmap2.png'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <section >
        <div className={style.FooterFlex} data-aos="fade-up">
          <Row>
          <Col lg={6} md={6} xl={6} xxl={2}>
              <h4 >Welcome to <span className='text-danger'>PG Click</span></h4>                    
              <ul>
              </ul>
            </Col>
            <Col lg={5} md={6} xl={5} xxl={2} className={style.FooterRow}>
              <ul className='pointer'>
                <h4 className={style.FooterTitle}>Customer Support</h4>
                <li onClick={() => navigate('/helpcenter')}>Help Center</li>
                <li onClick={() => navigate('/return&cancellationpolicy')}>Return & Cancellation Policy</li>
                <li onClick={() => navigate('/deliverypolicy')}>Shipping & Delivery Policy</li>
              </ul>
            </Col>         
            <Col lg={2} md={6} xl={2} xxl={2}>
              <ul className='pointer'>
                <h4 className={style.FooterTitle}>About Us</h4>
                <li onClick={() => navigate('/aboutourcompany')}>About Our Company</li>
                <li onClick={() => navigate('/successstories')}>Success Stories</li>
                <li onClick={() => navigate('/contactus')} >Contact Us</li>
                <li>Make a Payment</li>
              </ul>
            </Col>          
            <Col lg={6} md={6} xl={6} xxl={2}>
              <ul className='pointer'>
                <h4 className={style.FooterTitle}>For Buyers</h4>
                <li onClick={() => navigate('/post_your_requirement')}>Post Your Requirement</li>
                <li onClick={() => navigate('/browse_suppliers')}>Browse Suppliers</li>
                <li onClick={() => navigate('/subscribe_trade')}>Subscribe sell Trade Alerts</li>
              </ul>
            </Col>           
            <Col lg={6} md={6} xl={6} xxl={3}>



              <h4 >Download Our New App</h4>
              <p>Scan the QR Code to download the app
                on your smartphone</p>
              <div className='d-flex  align-items-center gap-3'>
                <img src={qrcode} alt="" />
                <div className='d-flex  flex-column'>
                  <a href="https://play.google.com/store/search?q=pg+click&c=apps&hl=en_IN&gl=US" target="_blank" rel="noopener noreferrer">
                    <img src={play} alt="" />
                  </a>
                </div>
              </div>
              <ul>
              </ul>


            </Col>
            <hr />
          </Row>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <span className={style.country}>Surat | Valsad | Ankleshwar | Rajkot | Bardoli | Vapi | Vadodara  | Gandhinagar | Ahmedabad | Bharuch | Jamnagar | </span>
            </div>
            <div className='d-flex gap-3'>
              <span onClick={() => navigate('/privacypolicy')} className={style.link}>Privacy Policy</span>
              <span onClick={() => navigate('/termsConditions')} className={style.link}>Terms & Conditions</span>
            </div>
          </div>
          <hr />
          <p className={style.copyright}>© 2016-2024 PG Click Universal Private Limited. All rights reserved.</p>
        </div>
      </section>
    </>
  )
}

export default Footer
