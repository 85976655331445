import React, { useState, useEffect } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import ProfileSidebar from '../../Common/ProfileSidebar/ProfileSidebar';

const UserDashboardProfile = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    mobile: '',
    email: '',
    // Add other profile information here
  });

  useEffect(() => {
    // Fetch user details from API
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/userdetails/${localStorage.getItem('userId')}`);
      const data = await response.json();
      if (data.ResponseCode === 1) {
        const { full_name, phone, email } = data.ResponseData.userdetails;
        setProfileData({ name: full_name, mobile: phone, email });
      } else {
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/update-user/${localStorage.getItem('userId')}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          full_name: profileData.name,
          phone: profileData.mobile,
          email: profileData.email,
        }),
      });
      const data = await response.json();
      if (data.ResponseCode === 1) {
        console.log('Profile updated successfully');
        // Optionally, you can show a success message or update the UI accordingly
      } else {
        console.error('Failed to update profile:', data.ResponseText);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  return (
    <Container fluid className='ps-0'>
      <div className='row gap-xxl-5'>
        <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
          <ProfileSidebar />
        </div>
        <div className='col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
          <h2 className='mt-5 text-uppercase'>Profile</h2>
          <Form onSubmit={handleSubmit}>
            {/* Profile fields */}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" name="name" value={profileData.name} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="text" placeholder="Enter your mobile number" name="mobile" value={profileData.mobile} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email address" name="email" value={profileData.email} onChange={handleChange} />
            </Form.Group>

            {/* Submit Button */}
            <Button className='btnpink' type="submit">
              Save Changes
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
}

export default UserDashboardProfile;
