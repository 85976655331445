import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';
import { Facebook, FacebookSharp, Instagram, LinkSharp, LinkedIn, Twitter, YouTube } from '@mui/icons-material';

const SocialMedia = () => {
    const [socialLinks, setSocialLinks] = useState({
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        youtube: ''
    });
    const [companyId] = useState(localStorage.getItem('clientId'));

    useEffect(() => {
        fetchSocialMediaLinks();
    }, []);

    const fetchSocialMediaLinks = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientsocialurl/${companyId}`);
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Social media links fetched successfully:', data.ResponseData);
                const { fb_url, twitter_url, linkeln_url, instagram_url, youtube_url } = data.ResponseData;
                setSocialLinks({
                    facebook: fb_url || '',
                    twitter: twitter_url || '',
                    linkedin: linkeln_url || '',
                    instagram: instagram_url || '',
                    youtube: youtube_url || ''
                });
            } else {
                console.error('Error fetching social media links:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching social media links:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientsocialurl-update/${companyId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fb_url: socialLinks.facebook,
                    twitter_url: socialLinks.twitter,
                    linkeln_url: socialLinks.linkedin,
                    instagram_url: socialLinks.instagram,
                    youtube_url: socialLinks.youtube
                })
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Social media links updated successfully:', data.ResponseText);
            } else {
                console.error('Error updating social media links:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error updating social media links:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSocialLinks({ ...socialLinks, [name]: value });
    };

    return (
        <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                    <SideBar />
                </div>
                <div className='col-8 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <h2 className='mt-5 text-uppercase'>Social Media</h2>
                    <p>Update Your official Social Media Urls.Please make sure that You are Submitting Your active Social Media Urls only.</p>
                    <Form onSubmit={handleSubmit} className='w-50'>
                        <Form.Group className="mb-3" controlId="formFacebook">
                            <Form.Label><FacebookSharp />  Facebook</Form.Label>
                            <Form.Control type="text" placeholder="Enter Facebook URL" name="facebook" value={socialLinks.facebook} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formTwitter">
                            <Form.Label><Twitter /> Twitter</Form.Label>
                            <Form.Control type="text" placeholder="Enter Twitter URL" name="twitter" value={socialLinks.twitter} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLinkedin">
                            <Form.Label> <LinkedIn /> LinkedIn</Form.Label>
                            <Form.Control type="text" placeholder="Enter LinkedIn URL" name="linkedin" value={socialLinks.linkedin} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formInstagram">
                            <Form.Label> <Instagram /> Instagram</Form.Label>
                            <Form.Control type="text" placeholder="Enter Instagram URL" name="instagram" value={socialLinks.instagram} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formYoutube">
                            <Form.Label>    <YouTube />  YouTube</Form.Label>
                            <Form.Control type="text" placeholder="Enter YouTube URL" name="youtube" value={socialLinks.youtube} onChange={handleChange} />
                        </Form.Group>

                        <Button className='btn btnpink'  type="submit">
                            Save
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default SocialMedia;
