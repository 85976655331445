import goodfood from '../../../images/food.png';
 import asia from '../../../images/asia.png';
import megashow from '../../../images/megashow.png';
import incense from '../../../images/incense.png';

export const UPCOMINGTRADESCHOW = [
    {
        img: goodfood,
        title: 'Gulfood 2024',
        date: 'Mon, 19 Feb, 2024 - Fri, 23 Feb, 2024',
        location: 'Dubai World Trade Centre - DWTC',
    },
    {
        img: asia,
        title: 'Asia Apparel Expo 2024',
        date: 'Mon, 19 Feb, 2024 - Wed, 21 Feb, 2024',
        location: 'MESSE BERLIN EXHIBITION GROUNDS',
    },
    {
        img: megashow,
        title: 'MEGA SHOW Bangkok 2024',
        date: 'Wed, 17 Jul, 2024 - Sat, 20 Jul, 2024',
        location: 'BITEC - Bangkok International Trade & Exhibition…',
    },
    {
        img: incense,
        title: 'Incense Media Expo 2024',
        date: 'Sat, 24 Feb, 2024 - Sun, 25 Feb, 2024',
        location: 'EKA Club',
    },
    {
        img: goodfood,
        title: 'Gulfood 2024',
        date: 'Mon, 19 Feb, 2024 - Fri, 23 Feb, 2024',
        location: 'Dubai World Trade Centre - DWTC',
    },
    {
        img: asia,
        title: 'Asia Apparel Expo 2024',
        date: 'Mon, 19 Feb, 2024 - Wed, 21 Feb, 2024',
        location: 'MESSE BERLIN EXHIBITION GROUNDS',
    },
    {
        img: megashow,
        title: 'MEGA SHOW Bangkok 2024',
        date: 'Wed, 17 Jul, 2024 - Sat, 20 Jul, 2024',
        location: 'BITEC - Bangkok International Trade & Exhibition…',
    },
    {
        img: incense,
        title: 'Incense Media Expo 2024',
        date: 'Sat, 24 Feb, 2024 - Sun, 25 Feb, 2024',
        location: 'EKA Club',
    },
]