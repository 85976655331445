import React, { useState, useEffect } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import style from './AdvertisementBanner.module.css';
import image1 from '../../../images/image 1.png'
const AdvertisementBanner = () => {
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    // Fetch the banner data from your API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/home`); // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        const data = await response.json();
        setBannerData(data.ResponseData.adbannerList);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <Container fluid className='ps-xxl-5 pe-xxl-5'>
        <Carousel>
        {bannerData.map((banner) => (
          <Carousel.Item key={banner.id}>
            <div className='mt-50' data-aos="fade-up" data-aos-duration="2000">
              <a href={banner.bannder_link} target="_blank" rel="noopener noreferrer">
                <img className={style.AdvertisementBanner} src={banner.image} alt={`Banner ${banner.id}`} />
              </a>
            </div>
          </Carousel.Item>
        ))}
        </Carousel>
    </Container>
  );
};

export default AdvertisementBanner;
