import Reac, { useEffect, useState } from 'react';
import style from './Description.module.css';
import span from '../../../images/span.bar-icon.png';
import { Button, Container } from 'react-bootstrap';
import axios from 'axios';

const Description = () => {
  const [clientdata, setClientdata] = useState([]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/clients-detail/${localStorage.getItem('company_id')}`);
        setClientdata(response.data.ResponseData.clientsdata);
      } catch (error) {
        console.error('API request failed:', error.message);
      }
    };

    fetchClientData();
  }, []);
  return (
    <>
      <section >
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <div className={style.DescriptionFlex} data-aos="fade-up" data-aos-duration="2000">
            <h5 className={style.dec}> <img src={span} alt="" /> About us</h5>
            <div className={style.clientData}>
              {clientdata.map((client, index) => (
                <div key={index} className={style.clientCard}>
                  <h5>{client.company_name}</h5>
                  <p>{client.address},
                    {client.cityname},
                    {client.statename}</p>
                  <p>Contact: {client.contact1}, {client.contact2}</p>
                  <a href={`http://${client.web_url}`} target="_blank" rel="noopener noreferrer">{client.web_url}</a>
                </div>
              ))}
            </div>
            <div className='d-flex align-items-center gap-2 '>

            </div>
            <hr />
          </div>
          <div>
          </div>
        </Container>

      </section>

    </>
  )
}

export default Description
