import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import style from './Login.module.css'
import loginimag from '../../images/loginImage.png'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
function Login() {

    const [phoneNumber, setPhoneNumber] = useState('');
    const navigat = useNavigate();
    useEffect(() => {
        localStorage.setItem('phoneNumber', phoneNumber);
    }, [phoneNumber]);
    // Function to handle form submission
    const handleLogin = async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    login_type: 'phone',
                    device_token: 'fdsfds',
                    device_type: 'web',
                    device_id: 'dfdfds',
                    phone: phoneNumber,

                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            // Check the API response for success
            if (responseData.ResponseCode === 1) {
                // Assuming you want to do something with the OTP response
                const otpResponseData = JSON.parse(responseData.ResponseData);
                navigat('/otp', { state: { otpResponseData } });
                console.log('OTP Response:', otpResponseData);
                toast.success('OTP sent successfully!');
                // Add your logic to navigate or handle successful login here
            } else {
                // Handle other response cases (e.g., show an error message)
                console.error('API Error:', responseData.ResponseText);
                toast.error('Please Enter Valid Phone Number');
            }
        } catch (error) {
            console.error('Error during API call:', error.message);
            toast.error(' Please Enter  Phone Number');
            // Handle network or other errors
        }

    };
    return (
        <>
            <Container fluid className='ps-xxl-5 pe-xxl-5'>
                <Row>
                    <Col lg={7} md={6} data-aos="fade-up" data-aos-duration="2000" className='mt-5'>
                        <div className={style.loginimagee}>
                            <img src={loginimag} className={style.loginimage} />
                        </div>
                    </Col>
                    <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="2000">
                        <div className='mt-5 d-flex flex-column gap-3 otpheading'>
                            <h1 className={style.OTPTitle} >Login !</h1>
                            <span className={style.otpPlease}>Please login to continue with us</span>
                            <form className='d-flex flex-column  w-75 mt-4  loginform '>
                                <div className={style.otpinputt}>
                                    <input
                                        type='number'
                                        className='form-control px-3 py-3 '
                                        placeholder='+91 '
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                        }}
                                    />
                                </div>
                                <span className={style.otpEnter}>You will received an OTP This number</span>
                            </form>
                            <div className={style.otpbuttonn}>
                                <Button type='submit' className="btnpink w-75 py-3 otppadding" onClick={handleLogin} >GET OTP</Button>

                            </div>
                            <span className={style.clicklogin}>By clicking Login, I agree to PG Click
                                <br />
                                <a href='/termsConditions'>Terms of Use</a> and <a href='/privacypolicy' >Privacy Policy</a>.</span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    )
}

export default Login