import React from 'react'
import ProfileSidebar from '../../Common/ProfileSidebar/ProfileSidebar'
import { Container } from 'react-bootstrap'
import UserDashboardProfile from '../UserDashboardProfile/UserDashboardProfile'

const UserDashboard = () => {
  return (
    <>
     <section>
               <Container fluid className='ps-0'>            
               <div className='row' id='dashboard'>
                    <div className='col-4 col-md-6 col-lg-2 col-xl-2 col-xxl-2 dashboardLeft mt-1' >
                        <ProfileSidebar />
                    </div>
                    <div className='col-7  col-md-6 col-lg-10 col-xl-10 col-xxl-10 dashboardRight mt-1 '>
                    {/* <UserDashboardProfile /> */}
                    </div>
                </div>
               </Container>
            </section>
    </>
  )
}

export default UserDashboard
