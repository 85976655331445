import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';


const LeaderBusinessDetail = () => {
    const [companyId] = useState(localStorage.getItem('clientId'));
    const [businessDetails, setBusinessDetails] = useState({
        company_name: '',
        contact_name: '',
        establishment: '',
        annual_turnover: '',
        company_type: '',
        working_days: '',
        employee_count: '',
        gst_number: '',
        pancard_number: '',
        aadharcard_number: '',
    });

    useEffect(() => {
        fetchBusinessDetails();
    }, []);

    const fetchBusinessDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/client_business_details/${companyId}`);
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Business details fetched successfully:', data.ResponseData);
                setBusinessDetails(data.ResponseData);
            } else {
                console.error('Error fetching business details:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching business details:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBusinessDetails({ ...businessDetails, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/clientbusiness-update/${companyId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(businessDetails),
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Business details updated successfully:', data.ResponseData);
            } else {
                console.error('Error updating business details:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error updating business details:', error);
        }
    };
    return (
        <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                    <SideBar />
                </div>
                <div className='col-8 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <h2 className='mt-5 text-uppercase'>Business details</h2>
                   
                      <Form onSubmit={handleSubmit} >
                     
                        <h3>Company Information</h3>
                        <Row>
                            <Col md={6}>
                            <Form.Group className="mb-3">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter company name" name="company_name" value={businessDetails ? businessDetails.company_name : ''} onChange={handleInputChange} />
                        </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>CEO Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter CEO name" name="contact_name" value={businessDetails ? businessDetails.contact_name : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Established Year</Form.Label>
                                    <Form.Control type="text" placeholder="Enter established year" name="establishment" value={businessDetails ? businessDetails.establishment : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Annual Turnover</Form.Label>
                                    <Form.Control type="text" placeholder="Enter annual turnover" name="annual_turnover" value={businessDetails ? businessDetails.annual_turnover : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Company Type</Form.Label>
                                    <Form.Control type="text" placeholder="Enter company type" name="company_type" value={businessDetails ? businessDetails.company_type : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Working Days</Form.Label>
                                    <Form.Control type="text" placeholder="Enter working days" name="working_days" value={businessDetails ? businessDetails.working_days : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Total Employees</Form.Label>
                                    <Form.Control type="text" placeholder="Enter total employees" name="employee_count" value={businessDetails ? businessDetails.employee_count : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h3>Statutory Details</h3>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>GSTIN</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN" name="gst_number" value={businessDetails ? businessDetails.gst_number : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control type="text" placeholder="Enter PAN" name="pancard_number" value={businessDetails ? businessDetails.pancard_number : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Aadhar</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Aadhar number" name="aadharcard_number" value={businessDetails ? businessDetails.aadharcard_number : ''} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className='btn btnpink' type="submit">
                            Save
                        </Button>
                  
              
                    </Form>
                </div>
            </div>
        </Container>
    );
}

export default LeaderBusinessDetail;
