import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Style from './DetailsPage.module.css';
import { Button, Container, Modal } from 'react-bootstrap';
import eye from '../../../images/eye.png';
import logo2 from '../../../images/logo2.png';
import banner from '../../../images/banner-1.jpg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import ProductList from '../../ProductView/ProductList';
import CompanyDetails from '../CompanyDetails/CompanyDetails';
import Description from '../Description/Description';

const DetailsPage = () => {
  const [index, setIndex] = useState(0);
  const [clientImages, setClientImages] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem('userId')
  const navigate = useNavigate();


  useEffect(() => {
    const fetchClientImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/clients-detail/${localStorage.getItem('company_id')}`);
        setClientImages(response.data.ResponseData.clients_office_image);
        setApiData(response.data.ResponseData.clientsdata);
      } catch (error) {
        console.error('API request failed:', error.message);
      }
    };

    fetchClientImages();
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    company_name: '',
    mobile_number: '',
    city_name: '',
    requirements: '',
    comments: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleShowModal = () => {
    if (userId) {
      setShowModal(true);
    } else {
      navigate('/login');
    }
  };

  const handleSubmit = () => {

    axios.post(`${process.env.REACT_APP_API}/api/post-inquiry`, formData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log(response.data);
        handleCloseModal();
      })
      .catch(error => {
        console.error('Error submitting form:', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/clients-detail/${localStorage.getItem('userId')}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setApiData(data.ResponseData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section>
        <div className={Style.detailsFlex}>
          <Carousel activeIndex={index} onSelect={handleSelect} style={{ zIndex: -1 }}>
            {clientImages && clientImages.map((image, imageIndex) => (
              <Carousel.Item>
                <div>
                  <img src={image.image_url} alt={`Image ${imageIndex}`} className={Style.detailsFlex}
                    onClick={() => window.location.href = `/product-details/${image.id}`}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className={Style.call}>
            <Button className='btnpink px-5 d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block' onClick={handleShowModal} >Inquiry</Button>
            {/* Modal */}
            <Modal show={showModal} style={{ zIndex: 9999 }}>
              <Modal.Header closeButton onClick={handleCloseModal}>
                <Modal.Title>Inquiry</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label htmlFor="Name" className='mb-1'>Name</label>
                <input
                  type="text"
                  id='Name'
                  className="form-control mb-2"
                  placeholder='Enter Name'
                  name='name'
                  onChange={handleInputChange} />

                <label htmlFor="Company" className='mb-1'>Company Name</label>
                <input
                  type="text"
                  id='Company'
                  className="form-control mb-2"
                  placeholder='Enter Company Name'
                  name='company_name'
                  onChange={handleInputChange} />

                <label htmlFor="Mobile" className='mb-1'>Mobile Number</label>
                <input
                  type="number"
                  id='Mobile'
                  className="form-control mb-2"
                  placeholder='Enter Mobile Number'
                  name='mobile_number'
                  onChange={handleInputChange} />

                <label htmlFor="City" className='mb-1'>City</label>
                <input
                  type="text"
                  id='City'
                  className="form-control mb-2"
                  placeholder='Enter City Name'
                  name='city_name'
                  onChange={handleInputChange} />

                <label htmlFor="Requirements" className='mb-1'>Requirements</label>
                <input
                  type="text"
                  id='Requirements'
                  className="form-control mb-2"
                  placeholder='Enter Requirements'
                  name='requirements'
                  onChange={handleInputChange} />

                <label htmlFor="comments" className='mb-1'>comments</label>
                <textarea
                  type="text"
                  id='comments'
                  className="form-control mb-2"
                  placeholder='Enter comments'
                  name='comments'
                  onChange={handleInputChange} />
              </Modal.Body>
              <Modal.Footer>
                <Button className='btnpink' onClick={handleCloseModal}>
                  Close
                </Button>
                <Button className='btnpink' onClick={handleSubmit} >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className={Style.detailnavbar}>
          <div className='d-flex ' >
            <img src={apiData && apiData.logo ? apiData.logo : 'fallback_logo_url'} alt={apiData && apiData.company_name} className={Style.logo2} />
            {/* <ul className='d-flex gap-5 align-items-center'>
              <li><a href="#productlist">Product</a></li>
              <li><a href="#about">About us</a></li>
            </ul> */}
          </div>
        </div>
       <Container fluid className='mt-5'>
      <div className='border rounded p-3'>
      <Tabs defaultActiveKey="productlist" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="productlist" title="Product" tabClassName="ms-5">
            <ProductList />
          </Tab>
          <Tab eventKey="about" title="About us">
            <Description />
          </Tab>
        </Tabs>
      </div>
        </Container>
      </section>

    </>
  )
}

export default DetailsPage
