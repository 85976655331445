import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Bottomprofile } from '../Bottomprofile';
import { BottomNavigationn } from '../../Componenets/BottomNavigation';

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchQuery.trim() !== '') {
          const response = await axios.get(`${process.env.REACT_APP_API}/api/search/r?query=${searchQuery}`);
          setSearchResults(response.data.ResponseData);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error searching:', error);
      }
    };

    fetchData();
  }, [searchQuery]);

  return (
    <>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-3'>
              <input 
                type="text" 
                placeholder='Search' 
                className='w-100 rounded p-3' 
                style={{height: '50px'}} 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <ul className='mt-3 p-0 w-100'>
              {searchResults.map((result) => (
              <div className='' key={result.id}>
                {/* Render each search result with a link */}
                <Link to={`/productdetails/${result.id}`}>
                  <p className='text-black'>{result.name}</p>
                </Link>
              </div>
            ))}
              </ul>
            </div>
          </div>
        </div>
        <BottomNavigationn />
      </section>
    </>
  );
};

export default Search;
