import React, { useEffect, useState } from 'react'
import style from './Productviem.module.css';
import symbol from '../../images/Symbol.png';
import calender from '../../images/calenderrsymbol.png';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap'
function ProductList() {
  const { id } = useParams();
  const [displayCount, setDisplayCount] = useState(12);
  const [productList, setProductList] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/product-detail/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setProductList(data.ResponseData.sellermore_products || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const handleViewAll = () => {
    setViewAll(true);
    setDisplayCount(productList.length);
  };

  const handleViewLess = () => {
    setViewAll(false);
    setDisplayCount(12);
  };
  return (
    <>
      <Container fluid className='ps-xxl-5 pe-xxl-5 mt-5'>
        <div className={style.ProductListFlex} data-aos="fade-up" data-aos-duration="2000">
          <Row >
            {productList.slice(0, displayCount).map((item, index) => (
              <Col key={index} className='col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2 paddingStyle'>
                <Link to={`/productview/${item.id}`} >
                  <div className={style.ProductListBox}>
                    <div className={style.ProductImagee}>
                      <img src={item.product_image} alt="" className={style.ProductImage} />
                    </div>
                    <div className='p-3 d-flex flex-column'>
                      {/* <div className='d-flex justify-content-between align-items-center'> */}
                      {/* <div className='d-flex align-items-center gap-1'> */}
                      {/* <img src={symbol} alt="" /> */}
                      {/* <span className={style.categoryName}>Education</span> */}
                      {/* </div> */}
                      {/* <div> */}
                      {/* <span className={style.categoryName}>4000</span> */}
                      {/* </div> */}
                      {/* </div> */}
                      <div className='d-flex flex-column w-100'>
                        <h5 className={style.ProductTitle}>
                          {item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}
                        </h5>
                      </div>
                      {/* <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex align-items-center gap-1'>
                            <img src={symbol} alt="" />
                            <span className={style.categoryName}>Los Angeles</span>
                          </div>
                          <div className='d-flex align-items-center gap-1'>
                            <img src={calender} alt="" />
                            <span className={style.categoryName}>06 Oct, 2022</span>
                          </div>
                        </div> */}
                      <div className='d-flex flex-column w-100'>                      
                          <span className={style.discountPrice}>
                            Price:- ₹  {item.discounted_price ? item.discounted_price : 'Ask Price'}
                            {item.price &&
                              <span className={style.price}> ₹ {item.price}</span>
                            }
                          </span>                     
                        {/* <div className='d-flex align-items-center gap-1'>
                            <span className='rating'>4.7</span>
                            <span className={style.categoryName}>(50)</span>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
          {!viewAll && (
            <div className='text-center mt-5'>
              <Button className='btnpink px-5 fs-5' onClick={handleViewAll}>View All</Button>
            </div>
          )}
          {viewAll && (
            <div className='text-center mt-5'>
              <Button className='btnpink px-5 fs-5' onClick={handleViewLess}>View Less</Button>
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

export default ProductList