import React, { useState, useEffect } from 'react';
import style from './List.module.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { LISTING } from './List.utils';
import inquiry from '../../../images/SVG.png';
import { Link, useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { VerifiedUserRounded } from '@mui/icons-material';

const List = () => {
  const { subcategoryId } = useParams();
  const [productList, setProductList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  const [bannerList, setBannerList] = useState([]);



  // useEffect(() => {

  //   fetch(`${process.env.REACT_APP_API}/api/sub-category-list/1`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.ResponseCode === 1) {
  //         setBannerList(data.ResponseData.categorywisebannerList);
  //       } else {
  //         console.error('Error fetching banner data:', data.ResponseText);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching banner data:', error);
  //     });
  // }, []);


  useEffect(() => {
    // Fetch data from the API
    fetch(`${process.env.REACT_APP_API}/api/productlist-subcategory-wise/${subcategoryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.ResponseCode === 1) {
          setProductList(data.ResponseData);
        } else {
          // Handle error if needed
          console.error('Error fetching product data:', data.ResponseText);
        }
      })
      .catch((error) => {
        // Handle fetch error
        console.error('Error fetching product data:', error);
      });
  }, [subcategoryId]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = productList.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Carousel>
        <Carousel>
          {bannerList.map((banner) => (
            <Carousel.Item key={banner.id}>
              <div className={style.BannerFlex}>
                <img src={banner.image} className={style.bannerImage} alt='banner image' />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Carousel>
      <section>
        <Container fluid className='ps-xxl-5 pe-xxl-5 List' >
          <div className='mt-3 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5'>
            <span className={style.show} data-aos="fade-up" data-aos-duration="2000">
              Showing <span className={style.count}>{indexOfFirstProduct + 1}-{indexOfLastProduct} </span> of {productList.length} Results
            </span>
          </div>
          <Row>
            <Col lg={8} md={8} sm={12} data-aos="fade-up" data-aos-duration="2000">
              {currentProducts.map((ele) => (
                <Link to={`/productview/${ele.id}`} key={ele.id}>
                  <div
                    className={`d-flex align-items-center gap-4 mt-1  p-2  ${style.listItem}`}
                    style={{
                      borderRadius: '10px',
                      backgroundColor: '#FFF',
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <div className={style.listImage}>
                      <img src={ele.product_image} alt="" className={style.listimage} />
                    </div>
                    <div className={`d-flex flex-column p-1 ${style.listContent}`}>
                      <h3 className={style.titlelist}>
                        {ele.name.length > 20 ? ele.name.slice(0, 20) + '...' : ele.name}
                      </h3>
                      <span className='text-muted fw-bold'>{ele.company_name}</span>
                      <span className='text-muted'>{ele.cityname}</span>
                      <span className='text-success'><VerifiedUserRounded />{ele.trust_verified_name}</span>
                      <div className='d-flex gap-3 flex-column'>
                        <span className='text-muted'>
                          Price:- {ele.discounted_price ? `${ele.discounted_price} ${ele.unit_type}` : 'Ask Price'}
                        </span>
                        <div>
                          <Button className={`btngray py-1  ${style.latestPrice}`}>Get Latest Price</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}

              <Pagination className='mt-5'>
                <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                {[...Array(Math.ceil(productList.length / productsPerPage))].map((_, index) => (
                  <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={indexOfLastProduct >= productList.length} />
              </Pagination>
            </Col>

            <Col lg={4} md={4} sm={12} className=' d-none  d-lg-block d-md-block d-xl-block d-xxl-block' data-aos="fade-up" data-aos-duration="2000">
              <div
                style={{
                  borderRadius: '10px',
                  backgroundColor: ' #D9D9D9',
                  width: '100%',
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                  height: '600px',
                }}
              ></div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default List;
