import React from 'react';
import style from './Header.module.css';

const Header = ({name,subname}) => {
  return (
    <>
    <div className={style.headerbox}>
       <h1>{name}</h1>
       <p>Home/{subname}</p>
   </div>
</>
  )
}

export default Header
