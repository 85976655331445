import React from 'react';
import { Container } from 'react-bootstrap';

const ReturnCancellationpolicy = () => {
  return (
   <>
   <section>
    <Container>
        <h1 className='mt-5 text-uppercase text-center'>Return & Cancellation Policy</h1>
    </Container>
   </section>
   </>
  )
}

export default ReturnCancellationpolicy
