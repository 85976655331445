import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const Product = () => {
    const [activeTab, setActiveTab] = useState('details');
    const [productVariants, setProductVariants] = useState([]);
    const [productKeywords, setProductKeywords] = useState([]);
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const userId = localStorage.getItem('userId');
    const clientId = localStorage.getItem('clientId');
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        subcategory_id: '',
        user_id: userId ,
        clients_id: clientId,
        price: '',
        discounted_price: '',
        unit_type: '',
        description: '',
        discount: ' ',
        is_active: '',
        product_image: null,
        keyword: '',
        variant_name: '',
        variant: '',
        product_code: '',
        status: '2',
    });
    const [id, setId] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/api/categorylist`);
                setCategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching categories: ', error);
            }
        };

        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/api/subcategory-list`);
                setSubcategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching subcategories: ', error);
            }
        };


        fetchCategories();
        fetchSubcategories();
    }, []);
    useEffect(() => {
        // Fetch data from the API
        fetch(`${process.env.REACT_APP_API}/api/unit-list`) // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
            .then(response => response.json())
            .then(data => {
                // Update the state with the fetched data
                setUnitTypes(data.ResponseData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    // Details //
    const handleSave = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        
        try {
            const form = new FormData();
            form.append('name', formData.name);
            form.append('user_id', formData.user_id);
            form.append('clients_id', formData.clients_id);
            form.append('category_id', formData.category_id);
            form.append('subcategory_id', formData.subcategory_id);
            form.append('unit_type', formData.unit_type);
            form.append('price', formData.price);
            form.append('product_code', formData.product_code);
            form.append('discounted_price', formData.discounted_price);
            form.append('description', formData.description);
            form.append('product_image', selectedImage);
            form.append('is_active', formData.is_active);
            form.append('discount', formData.discount);
            form.append('status', formData.status);
    
            const response = await axios.post(`${process.env.REACT_APP_API}/api/add-product`, form);
    
            if (response.data.ResponseCode === 1) {
                console.log('Product added successfully:', response.data.ResponseData);
                // Store the id in localStorage
                const productId = response.data.ResponseData.id;
                localStorage.setItem('product_id', productId);
                toast.success('Product added successfully!');
                setActiveTab('specification'); // Change the active tab after successful form submission
            } else {
                console.error('Error adding product:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
  


    // Specifications //
    const [showProductLinkTable, setShowProductLinkTable] = useState(false);
const handleShowProductLinkTable = () => {
    setShowProductLinkTable(true);
};
const handleCloseProductLinkTable = () => {
    setShowProductLinkTable(false);
};
    const handleSpecification = async () => {
        try {
            const form = new FormData();
            form.append('product_id', localStorage.getItem('product_id'));
            form.append('variant_name', formData.variant_name);
            form.append('variant', formData.variant);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/add-validator`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                console.log('Product image added successfully:', response.data.ResponseData);
                toast.success('Product Specification Added successfully!');
                fetchProductVariants();
            } else {
                console.error('Error adding product image:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };
    // Get Specification //
    const fetchProductVariants = async () => {
        const product_id = localStorage.getItem('product_id');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/product-variant-list/${product_id}`);
            setProductVariants(response.data.ResponseData);
        } catch (error) {
            console.error('Error fetching variants:', error.message);
        }
    };
    useEffect(() => {
        fetchProductVariants();
    }, []);
    // Delete Specification //
    const handleDeleteVariant = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/validator-delete/${id}`,
            );

            if (response.data.ResponseCode === 1) {
                console.log('Variant deleted successfully:', response.data.ResponseText);
                toast.success('Product Specification deleted successfully!');
                setProductVariants((prevVariants) => prevVariants.filter((variant) => variant.id !== id));
                setGeneralTabFilled(true);

            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    // Update Specification //
    const [ShowEditProductTable, setShowEditProductTable] = useState(false);
    const handleShowEditProductTable = () => {
        setShowEditProductTable(true);
    };
    const handleCloseEditProductTable = () => {
        setShowEditProductTable(false);
    };
 
    const handleUpdate = () => {
        const apiUrl = `${process.env.REACT_APP_API}/api/validator-update/${id}`;

        fetch(apiUrl, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                toast.success('Product Specification updated successfully!');
                // Update the product list after updating
                fetchProductVariants();
                handleCloseEditProductTable();
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Failed to update product. Please try again.');
            });
    };



    // Image upload  //
    const [showImageTable, setShowImageTable] = useState(false);
    const handleShowImageTable = () => {
        setShowImageTable(true);
    };
    const handleCloseImageTable = () => setShowImageTable(false);
    const handleImageUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', localStorage.getItem('product_id'));
            form.append('image_url', selectedImage);
            form.append('status', 1);
            form.append('comment', 'asdadad');

            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/add-image`,
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                console.log('Product image added successfully:', response.data.ResponseData);
                toast.success('Product image added successfully!');
                // After successfully adding the image, update the image list
                fetchData();
            } else {
                console.error('Error adding product image:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };
    // Get Image //
    const [images, setImages] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/product-image-list/${localStorage.getItem('product_id')}`, {

            });

            if (response.data.ResponseCode === 1) {
                // Set the retrieved images in the state
                setImages(response.data.ResponseData);
            } else {
                console.error('Error fetching images:', response.data.ResponseText);
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    // Delete Image //
    const handleDeleteImage = async (imageId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/image-delete/${imageId}`
            );

            if (response.data.ResponseCode === 1) {
                console.log('Image deleted successfully:', response.data.ResponseText);
                setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
                toast.success('Product image deleted successfully!');
                setGeneralTabFilled(true);
            } else {
                console.error('Error deleting image:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };




    // Keyword //
    const [showKeywordTable, setShowKeywordTable] = useState(false);
    const handleShowKeywordTable = () => {
        setShowKeywordTable(true);
    };
    const handleCloseKeywordTable = () => setShowKeywordTable(false);
    const handleKeywordUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', localStorage.getItem('product_id'));
            form.append('keyword', formData.keyword);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/add-keyworld`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Product Keyword added successfully!');
                fetchProductKeywords();
            } else {
                toast.error('Failed to add product. Please try again.');
                console.error('Error adding product image:', response.data.ResponseText);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
            // console.error('API request failed:', error.message);
        }
    };
    // Get Keyword //
    useEffect(() => {
        fetchProductKeywords();
    }, []);
    const fetchProductKeywords = async () => {
        const product_id = localStorage.getItem('product_id');

        if (product_id) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/product-keyword-list/${product_id}`);
                const data = await response.json();

                if (data.ResponseCode === 1) {
                    setProductKeywords(data.ResponseData);
                } else {
                    console.error('Error fetching product keywords:', data.ResponseText);
                }
            } catch (error) {
                console.error('Error during API call:', error);
                // toast.error('An error occurred. Please try again.');
            }
        }
    };
    // Delete Keyword //
    const handleDeleteKeyword = async (productskeyword_id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/delete-keyworld/${productskeyword_id}`,                
            );

            if (response.data.ResponseCode === 1) {
                setProductKeywords((prevKeywords) => prevKeywords.filter((keyword) => keyword.productskeyword_id !== productskeyword_id));
                setGeneralTabFilled(true);
                toast.success('Product Keyword deleted successfully!');
            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);          
        }
    };


  
    return (
        <>
            <section className="content " >
                <Container fluid className='ps-0'>
                    <div className="body_scroll row" id='product' >
                        <div className="block-header">
                        </div>
                        <div>
                            <div className="row clearfix">
                                <div className='col-3 col-lg-2 mt-1'>
                                    <SideBar />
                                    </div>
                                <div className="col-9 col-lg-10 mt-1">
                                    <div className="pagetitle  mb-2 mt-3">
                                        <nav className='d-flex  justify-content-between'>
                                            <div>
                                                <h4> ADD Product</h4>
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                                    <li className="breadcrumb-item">Product</li>
                                                </ol>
                                            </div>
                                            <div className="d-flex align-items-center gap-1">
                                                <Button
                                                    type="submit"
                                                    data-form="form-product"
                                                    data-bs-toggle="tooltip"
                                                    title class="btn btn-primary"
                                                    data-bs-original-title="Save"
                                                    onClick={() => {localStorage.removeItem('product_id');
                                                                    window.location.href = "/productlist";
                                                    }}>
                                                    <i class="bi bi-floppy2"/>
                                                </Button>
                                                <a href="/viewgeneral" data-bs-toggle="tooltip" title class="btn btn-secondary" data-bs-original-title="Back" aria-label="Back">
                                                    <i class="bi bi-reply-fill" />
                                                </a>
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="card">
                                        <div className="body  p-xxl-5 p-xl-5 p-lg-5">
                                            <Tabs id="controlled-tab-example"
                                                activeKey={activeTab}
                                                onSelect={(k) => setActiveTab(k)}
                                                className="mb-3" >
                                                <Tab eventKey="details"
                                                 title={<span> Details</span>}
                                                  onSelect={() => setGeneralTabFilled(false)}
                                                  >
                                                <form onSubmit={handleSave} id="form-product">
                                                    <div className='productmargin d-flex flex-column m-3 pt-xxl-5 pt-xl-5 pt-lg-5'  >
                                                      
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3' >
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap">Product Name :-</label>
                                                                <input type="text" className="form-control " value={formData.name}
                                                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })} name='name' required />
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label">Category :-</label>
                                                                <select name="category_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => setFormData({ ...formData, category_id: e.target.value })} data-placeholder="Select" required>
                                                                    <option value={formData.category_id ? "" : ""}>
                                                                        --select category--
                                                                    </option>
                                                                    {categoryData &&
                                                                        categoryData.map((v, index) => {
                                                                            return (
                                                                                <option value={v.id} key={index.id}>
                                                                                    {v.category_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap ">Sub Category :-</label>
                                                                <select name="subcategory_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => setFormData({ ...formData, subcategory_id: e.target.value })} data-placeholder="Select" required>
                                                                    <option value={formData.subcategory_id ? "" : ""}>
                                                                        --select category--
                                                                    </option>
                                                                    {subcategoryData &&
                                                                        subcategoryData.map((v, index) => {
                                                                            return (
                                                                                <option value={v.id} key={index.id}>
                                                                                    {v.subcategory_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label ">ProductCode :-</label>
                                                                <input type="number" className="form-control" name='product_code' onChange={(e) => setFormData({ ...formData, product_code: e.target.value })}  />
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap ">unit:-</label>
                                                                <select
                                                                    name="unit_type"
                                                                    id="templateId"
                                                                    className="form-control show-tick ms select2"
                                                                    onChange={(e) => setFormData({ ...formData, unit_type: e.target.value })}
                                                                    value={formData.unit_type}
                                                                    data-placeholder="Select"
                                                                    required >
                                                                    <option>--select unit--</option>
                                                                    {unitTypes.map((unit, index) => (
                                                                        <option key={index} value={unit.unit_type_id}>
                                                                            {unit.unit_type}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label ">Price :-</label>
                                                                <input type="number" className="form-control" value={formData.price}
                                                                    onChange={(e) => setFormData({ ...formData, price: e.target.value })} name='price'  />
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap me-2">Discount Price :-</label>
                                                                <input type="" className="form-control" value={formData.discounted_price}
                                                                    onChange={(e) => setFormData({ ...formData, discounted_price: e.target.value })} name='discounted_price'  />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label ">Discount :-</label>
                                                                <input type="number" className="form-control" name='discount' value={formData.discount}  onChange={(e) => setFormData({ ...formData, discount: e.target.value })}  />
                                                            </Col>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label text-nowrap ">Is Active :-</label>
                                                                <select
                                                                    name="is_active"
                                                                    id="templateId"
                                                                    className="form-control show-tick ms select2"
                                                                    data-placeholder="Select"
                                                                    onChange={(e) => setFormData({ ...formData, is_active: e.target.value })}
                                                                   >
                                                                    <option value={formData.is_active ? "" : ""}>select Status</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label">Short Discription :-</label>
                                                                <textarea type="text" className="form-control" value={formData.description}
                                                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })} name='description'  />
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col xs={10} md={10} lg={5} xl={5} xxl={5}>
                                                                <label className="form-label">Select Image :-</label>
                                                                <input type="file" className="form-control" value={formData.product_image}
                                                                    onChange={(e) => handleImageChange(e)} name='product_image' style={{ width: '100%' }} required />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='d-flex  justify-content-end mt-3 me-3'>
                                                        <button className="  btn btnpink text-white  ps-4 pe-4 "  type='submit'
                                                     >Save & Next</button>
                                                    </div>
                                                    </form>
                                                </Tab>
                                                <Tab eventKey="specification" title="Specification" disabled={!generalTabFilled}
                                                      onSelect={(k) => {
                                                        if (showProductLinkTable || ShowEditProductTable) {
                                                            // If either modal is open, prevent switching tabs
                                                            return;
                                                        }
                                                        // Otherwise, allow switching tabs
                                                        !generalTabFilled && setActiveTab('details');
                                                    }}
                                                    >
                                                    <div>
                                                        <div className="row clearfix">
                                                            <div className="col-12 col-lg-12">
                                                                <div className="card">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-title"> Specification</h5>
                                                                        <Button className="btngray1 addapc" 
                                                                            onClick={handleShowProductLinkTable}
                                                                        >      
                                                                                    Add Specification</Button>
                                                                        {/* modal */}
                                                                        <Modal show={showProductLinkTable} onHide={handleCloseProductLinkTable}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title className='text-center w-100' >Specification</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <Form>
                                                                                    <Form.Group className="mb-3" controlId="attributeId">
                                                                                        <div className="d-flex justify-content-between">
                                                                                            <Form.Label className='me-3 '>Specification:-</Form.Label>
                                                                                            <input type="text" name='variant_name' className='form-control'
                                                                                                onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })} />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    <Form.Group
                                                                                        controlId="attributeText"
                                                                                    >
                                                                                        <div className="d-flex  justify-content-between">
                                                                                            <Form.Label className='me-5'>variant:-</Form.Label>
                                                                                            <Form.Control as="input"
                                                                                                type="text" name='variant'
                                                                                                onChange={(e) => setFormData({ ...formData, variant: e.target.value })}
                                                                                            />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </Modal.Body>
                                                                            <Modal.Footer className='d-flex justify-content-center'>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        handleSpecification();
                                                                                        handleCloseProductLinkTable();
                                                                                    }}
                                                                                    className='btngray px-5'>
                                                                                    Save
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </div>

                                                                    <div className="m-2">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">NO</th>
                                                                                        <th scope="col">Specification</th>
                                                                                        <th scope="col">Text</th>
                                                                                        <th scope="col" style={{ marginLeft: "200px" }}>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {productVariants.map((variant, index) => (
                                                                                        <tr>
                                                                                            <td scope="col">{index + 1}</td>
                                                                                            <td scope="col">{variant.variant_name}</td>
                                                                                            <td scope="col">{variant.variant}</td>
                                                                                            <td scope="col" style={{ marginLeft: "200px" }}>
                                                                                                <Button className="btn btn-primary m-2" onClick={() => handleShowEditProductTable(variant.id)} >
                                                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                                                    </Button>
                                                                                                <button className="btn btn-danger ms-2 " onClick={() => handleDeleteVariant(variant.id)}
                                                                                                > <i class="bi bi-trash-fill me-1"></i></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <Modal show={ShowEditProductTable} onHide={handleCloseEditProductTable}>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title className='text-center w-100' >Edit-Specification</Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <Form>
                                                                                <Form.Group className="mb-3" controlId="attributeId">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <Form.Label className='me-3 '>Specification:-</Form.Label>
                                                                                        <input type="text" name='variant_name' className='form-control'
                                                                                            value={formData.variant_name}
                                                                                            onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })}
                                                                                        />
                                                                                    </div>
                                                                                </Form.Group>
                                                                                <Form.Group
                                                                                    controlId="attributeText"
                                                                                >
                                                                                    <div className="d-flex  justify-content-between">

                                                                                        <Form.Label className='me-5'>variant:-</Form.Label>
                                                                                        <Form.Control as="input"
                                                                                            type="text" name='variant'
                                                                                            value={formData.variant}
                                                                                            onChange={(e) => setFormData({ ...formData, variant: e.target.value })}
                                                                                        />
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </Form>
                                                                        </Modal.Body>
                                                                        <Modal.Footer className='d-flex justify-content-center'>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    handleUpdate();
                                                                                    handleCloseProductLinkTable();
                                                                                }}
                                                                                className='btngray px-5'>
                                                                                Save
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                    {/* <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                        <Button className="  btngray   ps-4 pe-4 " onClick={() => {
                                                                            setGeneralTabFilled(true);
                                                                        }}  >Save & Next</Button>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="image" title="Image"
                                                    disabled={!generalTabFilled}
                                                    onSelect={(k) => !generalTabFilled && setActiveTab('details')}  >
                                                    <div >
                                                        <div className="row clearfix">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-title"> Image</h5>
                                                                        <Button className="btngray1"
                                                                            onClick={handleShowImageTable}>Add Image</Button>
                                                                        {/* modal */}
                                                                        <Modal
                                                                            size="md"
                                                                            show={showImageTable}
                                                                            onHide={handleCloseImageTable}
                                                                            aria-labelledby="example-modal-sizes-title-sm"
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title id="example-modal-sizes-title-sm">
                                                                                    Upload  Image
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <img src="" alt="" style={{ maxWidth: '100%' }} />
                                                                                {/* <input type="file" /> */}
                                                                                <input type="file" name="image_url" id="image_url"
                                                                                    onChange={handleImageChange}
                                                                                />
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        handleImageUpload();
                                                                                        handleCloseImageTable();
                                                                                    }}
                                                                                    className="m-2 btngray px-3">
                                                                                    Upload
                                                                                </Button>
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                    </div>
                                                                    <div className="m-2">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">NO</th>
                                                                                        <th scope="col">Image</th>
                                                                                        <th scope="col">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {images.map((image, index) => (
                                                                                        <tr key={index}>
                                                                                            <td scope="col">{index + 1}</td>
                                                                                            <td scope="col">
                                                                                                <img src={image.image_url} alt="" style={{ maxWidth: '100px' }} />
                                                                                            </td>
                                                                                            <td scope="col" style={{ marginLeft: "200px" }}>
                                                                                                {/* <button className="btn btn-primary m-2">
                                                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                                                    Edit</button> */}
                                                                                                <button className="btn btn-danger ms-2 "
                                                                                                    onClick={() => handleDeleteImage(image.id)}
                                                                                                > <i class="bi bi-trash-fill me-1"></i></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                    {/* <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                        <Button className="btngray  ps-4 pe-4 " onClick={() => {
                                                                            // handleSave();
                                                                            setGeneralTabFilled(true);
                                                                        }}  >Save & Next</Button>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="keyword" title="Keyword"
                                                    disabled={!generalTabFilled}
                                                    onSelect={(k) => !generalTabFilled && setActiveTab('details')} >
                                                    <div >
                                                        <div className="row clearfix">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className="card-title">Keyword</h5>
                                                                        <Button className="btngray1"
                                                                            onClick={handleShowKeywordTable}
                                                                        >Add Keyword</Button>
                                                                        {/* modal */}
                                                                        <Modal
                                                                            size="md"
                                                                            show={showKeywordTable}
                                                                            onHide={handleCloseKeywordTable}
                                                                            aria-labelledby="example-modal-sizes-title-sm"
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title id="example-modal-sizes-title-sm">
                                                                                    Enter keyword
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <input type="text" name='keyword' placeholder='Enter keyword ' className='form-control' onChange={(e) => setFormData({ ...formData, keyword: e.target.value })} />
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        handleKeywordUpload();
                                                                                        handleCloseKeywordTable();
                                                                                    }}
                                                                                    className="mt-3 px-5 btngray ">
                                                                                    Save
                                                                                </Button>
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                    </div>
                                                                    <div className="m-2">
                                                                        <div className="table-responsive">
                                                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">NO</th>
                                                                                        <th scope="col">Keyword</th>
                                                                                        <th scope="col">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {productKeywords.map((keyword, index) => (
                                                                                        <tr>
                                                                                            <td scope="col">{index + 1}</td>
                                                                                            <td scope="col">{keyword.keyword}</td>
                                                                                            <td scope="col">
                                                                                                {/* <button className="btn btn-primary m-2">
                                                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                                                    Edit</button> */}
                                                                                                <button className="btn btn-danger ms-2 " onClick={() => handleDeleteKeyword(keyword.productskeyword_id)}
                                                                                                > <i class="bi bi-trash-fill me-1"></i></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>                                                                                                                                                                                       <div className='d-flex  justify-content-end mt-4 me-3'>
                                                                        {/* <Button className="  btngray ps-4 pe-4 " onClick={() => {
                                                                            handleSave();
                                                                            setGeneralTabFilled(true);
                                                                        }}  >Submit</Button> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <ToastContainer />
        </>
    )
}

export default Product
