import React from 'react'
import FeaturedProducts2 from '../Home/FeaturedProducts2/FeaturedProducts'

const OfferZone = () => {
  return (
    <>
    
   <FeaturedProducts2/> 
    </>
  )
}

export default OfferZone
