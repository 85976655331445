import React from 'react'
import { Container } from 'react-bootstrap'

const AboutOurCompany = () => {
  return (
 <>
 <section>
 <Container>
 <h1 className='mt-5 text-uppercase text-center'>About Our Company</h1>
 </Container>
 </section>
 </>
  )
}

export default AboutOurCompany
