import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import style from './CompanyDetails.module.css';
import briefcase from '../../../images/breifcase.svg.png';
import facebook from '../../../images/facebook.png';
import twitter from '../../../images/Twitter.png';
import linkedin from '../../../images/linkdin.png';
import instagram from '../../../images/instragaram.png';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CompanyDetails = () => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const { ProductListId } = useParams();



  useEffect(() => {
    const fetchProductVideo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/product-detail/${ProductListId}`);
        const videoUrl = response.data.ResponseData.productDetail[0].clients_details[0].video_url;
        setVideoUrl(videoUrl);
      } catch (error) {
        console.error('Error fetching product video:', error);
      }
    };

    fetchProductVideo();
  }, [ProductListId]);
  console.log(ProductListId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/clients-detail/${localStorage.getItem('company_id')}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setApiData(data.ResponseData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <section>
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <Row>
            <Col lg={8}>
              <div className={style.CompanyDetailsFlax} >
                <div>
                <h5>About {apiData?.clientsdata[0]?.company_name}</h5>
                </div>
                <hr />
                <div>
                </div>
                <div>
                  {videoUrl ? (
                    <div>
                      <video width={'100%'} height={470} controls>
                        <source src={videoUrl} />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>

              </div>
            </Col>
            <Col lg={4}>
              {apiData && (
                <div className={style.ContactDetails} >
                  <div className='d-flex align-items-center gap-2'>
                    <img src={briefcase} alt="" />
                    <h5 >Seller Contact Details</h5>
                  </div>
                  <hr />
                  <div className='d-flex flex-column gap-1 align-items-start' >
                  <span className={style.innovations}>{apiData?.clientsdata[0]?.company_name}</span>
                  <span className={style.innovations}>{apiData?.clientsdata[0]?.contact_name}</span>
                  <span>{apiData?.clientsdata[0]?.address}</span>
                    <span>{apiData?.clientsdata[0]?.contact1}</span>
                    <span>{apiData?.clientsdata[0]?.web_url}</span>
                    <div className='d-flex gap-2 mt-3'>
                      <img src={facebook} alt="" />
                      <img src={twitter} alt="" />
                      <img src={linkedin} alt="" />
                      <img src={instagram} alt="" />
                    </div>
                  </div>
                </div>
              )}
              <div className={style.contactFlex} >
                <h5>Contact Business</h5>
                <hr />
                <div className={style.companyForm}>
                  <form action="" className='d-flex flex-column gap-3'>
                    <input type="text" placeholder='Name' className='w-100' />
                    <input type="text" placeholder='Email' className='w-100' />
                    <textarea name="" id="" cols="30" rows="5" placeholder='' className='w-100' />
                    <Button className='btnpink'>Send Message</Button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default CompanyDetails
