import React, { useState, useEffect } from 'react';
import style from './FeatureProducts2.module.css';
import { Row, Col, Button, Container, Carousel } from 'react-bootstrap';
import Backgroundanimation from '../../Backgroundanimation';
import { Link } from 'react-router-dom';

const FeaturedProducts2 = () => {
  const [products, setProducts] = useState([]);
  const [feedName, setFeedName] = useState("");
  const [banners, setBanners] = useState([]);// State to hold the feedname

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/offerzone`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const { feedList, offerbanner } = data.ResponseData;
        if (feedList.length > 0) {
          setProducts(feedList[0].products_list_offer_zoon);
          setFeedName(feedList[0].feedname);
        }
        if (offerbanner.length > 0) {
          setBanners(offerbanner);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>

     
      <section>
      <Carousel interval={5000} pause='hover'> {/* Set interval and pause options */}
            {banners.map((banner, index) => (
              <Carousel.Item key={index}>
                <div className='mt-0 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5' data-aos="fade-up" data-aos-duration="2000">
                  <div className={style.BannerFlex}>
                    <img
                      className={style.AdvertisementBanner}
                      src={banner.imageurl}
                      alt={banner.name}
                      onClick={() => window.location.href = `/productdetails/${banner.bannder_linkid}`} // Adjust navigation link
                    />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        <Backgroundanimation />
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <h3 className='mt-5 mb-20' data-aos="fade-up" data-aos-duration="2000">{feedName}</h3> {/* Display feedname here */}
          <Row>
            {products.map((item, index) => (
              <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2' key={index}>
                <Link to={`/productview/${item.id}`} key={index}>
                  <div className={style.FeaturedFlex} data-aos="fade-up" data-aos-duration="2000">
                    <div className={style.ProductImagee}>
                      <img src={item.product_image} className={style.ProductImage} alt="" />
                    </div>
                    <div className='d-flex flex-column  w-100 px-2'>
                      <span className={style.ProductTitle}>
                        {item.name.length > 15 ? item.name.slice(0, 15) + '...' : item.name}
                      </span>
                      <span className={style.ProductPrice}>
                        Price:- {item.price ? `₹${item.price}` : 'Ask Price'}
                      </span>
                    </div>
                    <div className='d-none d-lg-flex d-xl-flex d-xxl-flex justify-content-center w-75 position-absolute ' style={{ bottom: '20px' }}>
                      <Button className='w-75 btngray1 inquirysend'>Get Best Price</Button>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FeaturedProducts2;
