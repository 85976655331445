import React, { useState, useEffect } from 'react';
import style from './CategoriesProductList.module.css';
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const CategoriesProductList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
  
    fetch(`${process.env.REACT_APP_API}/api/sub-category-list/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.ResponseCode === 1) {
          setBannerList(data.ResponseData.categorywisebannerList);
        } else {
          console.error('Error fetching banner data:', data.ResponseText);
        }
      })
      .catch((error) => {
        console.error('Error fetching banner data:', error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/home`);
        const data = await response.json();
        setCategories(data.ResponseData.categoryList);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
 return (
    <>
      <Carousel>
        <Carousel>
          {bannerList.map((banner) => (
            <Carousel.Item key={banner.id}>
              <div className={style.BannerFlex}>
                <img src={banner.imageurl} className={style.bannerImage} alt={banner.name} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Carousel>
      <section className='mt-5'>
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <Row>
            <h3 className='mb-3'>Browse by category</h3>
            {categories.map((item, index) => (
              <Col lg={2} md={3} xs={4} key={index} className='paddingStyle marginstyle' data-aos="fade-up" data-aos-duration="2000"  >
                <div>
                  <Link to={`/subcategories/${item.id}`}>
                    <div className={style.CategoryFlex}>
                      <div className={style.categoryImagee}>
                        <img src={item.image} alt="" className={style.categoryImg} />
                      </div>
                      <h6 className={style.categoryTitle}>{item.category_name}</h6>
                    </div>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}
export default CategoriesProductList
