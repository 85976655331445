import React, { useEffect, useState } from 'react';
import { Row, Button, Container } from 'react-bootstrap';
import style from './FeatureProduct3.module.css';
import { Link } from 'react-router-dom';
import Backgroundanimation from '../../Backgroundanimation';
import axios from 'axios';

const FeatureProducts3 = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/home`
    )
      .then((response) => response.json())
      .then((data) => {
        setFeaturedProducts(data.ResponseData.productList);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleProductClick = async (productId) => {
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/add-visitor`, {
        user_id: userId,
        productid: productId
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error adding visitor:", error);
    }
  };
  return (
    <>
      <section>
        <Backgroundanimation />
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <h3 className='mt-3 mb-3 mt-xxl-5 mt-xl-5 mt-lg-5' data-aos="fade-up" data-aos-duration="2000">Featured Products</h3>
          <Row>
            {featuredProducts.map((item, index) => (
              <div className='col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-xxl-2 paddingStyle ' key={index} data-aos="fade-up" data-aos-duration="2000">
                <Link to={`/productview/${item.id}`} key={index} onClick={() => handleProductClick(item.id)}>
                  <div className={style.FeaturedFlex} data-aos="fade-up" data-aos-duration="2000">
                    <div className={style.ProductImagee}>
                      <img src={item.product_image} className={style.ProductImage} alt="" />
                    </div>
                    <div className='d-flex flex-column  w-100 px-2'>
                      <span className={style.ProductTitle}>
                        {item.name.length > 15 ? item.name.slice(0, 15) + '...' : item.name}
                      </span>
                      <span className={style.ProductPrice}>
                        Price:- {item.price ? `₹${item.price}` : 'Ask Price'}
                      </span>
                    </div>
                    <div className='d-none d-lg-flex d-xl-flex d-xxl-flex justify-content-center w-75 position-absolute ' style={{ bottom: '20px' }}>
                          <Button className='w-75 btngray1 inquirysend'>Get Best Price</Button>
                          </div>
                  </div>
                </Link>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default FeatureProducts3
