import React, { useState } from 'react';
import phone from '../images/phonepe.webp';
import axios from 'axios';

const Phone = () => {
  const [form, setForm] = useState({ name: '', number: '', amount: '' });
  const verifyKey = "d7a8e4458caa6fcd781166bbdc85fec76740c18cb9baa9a4c48cf2387d554180###1";
  const basicAuthCredentials = btoa('PGTESTPAYUAT:099eb0cd-02cf-4e2a-8aca-3e6c6aff0399');
  const phonePeApiEndpoint = '/apis/pg-sandbox/pg/v1/pay';


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        phonePeApiEndpoint,
        JSON.stringify({
          merchantId: "PGTESTPAYUAT",
          merchantTransactionId: "MT7850590068188104",
          merchantUserId: "MUID123",
          amount: 10000,
          redirectUrl: "https://webhook.site/redirect-url",
          redirectMode: "REDIRECT",
          callbackUrl: "https://webhook.site/callback-url",
          mobileNumber: "9999999999",
          paymentInstrument: {
            type: "PAY_PAGE"
          }
        }),
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${basicAuthCredentials}`,
            'X-VERIFY': verifyKey,
          },
        }
      );
    
      console.log(response.data);
      
    } catch (error) {
     
      console.error(error.response.data);
    }
  };

  return (
    <>
      <div className='row mt-5'>
        <div className='col-6' >
          <img src={phone} alt="" />
        </div>
        <div className='col-6'>
          <div className='form d-flex flex-column'>
            <label htmlFor="Name">Name</label>
            <input type="text" placeholder='Enter Name' name='' />
            <label htmlFor="number">Number</label>
            <input type="number" name='' />
            <label htmlFor="number">Amount</label>
            <input type="number" name='' />
            <button className='btngray w-50 mt-5' onClick={handleSubmit} >Pay Now</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Phone;
