import { BusinessCenter, BusinessCenterRounded, BusinessRounded, BusinessSharp, Call, ContactEmergency, CorporateFareSharp, DisplaySettings, EditCalendar, Image, Leaderboard, ProductionQuantityLimits, SocialDistance, Verified, VideoCall } from "@mui/icons-material";

export const LEADERBOARD = [
    {
        icon: <ProductionQuantityLimits />,
        name: "Featured Product",
        number: 100,
        productvarient: "Cotton",
        color: "#FCD09A",
        backgroundColor: "#FFE4C2",
        title: "view more",
        navigate: () => { window.location.href = '/productlist' }
    },
  
    {
        icon: <EditCalendar />,
        name: "Inquiry",
        number: 100,
        productvarient: "Cotton",
        color: "#D2C2FF",
        backgroundColor: "#E5DCFF",
        title: "view more",
        navigate: () => { window.location.href = '/inquiry' }


    }
    ,
     {
        icon: <Call/>,
        name: "Contact",
       
        productvarient: "Cotton",
        color: "#79bff7",
        backgroundColor: "#CFE9FF",
        title: "view more",
        navigate: () => { window.location.href = '/contact' }

    }
    , {
        icon: <Image />,
        name: "Image",
      
        productvarient: "Cotton",
        color: "#ADDAD5",
        backgroundColor: "#BAE9E4",
        title: "view more",
        navigate: () => { window.location.href = '/imagepicker' }

    }, {
        icon: <VideoCall/>,
        name: "Video",
     
        productvarient: "Cotton",
        color: "#F2E19B",
        backgroundColor: "#FDEEB2",
        title: "view more",
        navigate: () => { window.location.href = '/video' }

    },  {
        icon: <Verified />,
        name: "KYC",
      
        productvarient: "Cotton",
        color: "#D6E395",
        backgroundColor: "#E3EDB4",
        title: "view more",
        navigate: () => { window.location.href = '/kyc' }
        
    }, {
        icon: <SocialDistance />,
        name: "Social Media", 
       
        productvarient: "Cotton",
        color: "#F1C3B5",
        backgroundColor: "#FFD6C9",
        title: "view more",
        navigate: () => { window.location.href = '/socialmedia' }


    }, {
        icon:<BusinessCenter />,
        name: "Business Website",
       
        productvarient: "Cotton",
        color: "#F2C0F0",
        backgroundColor: "#FFD7FD",
        title: "view more",
        navigate: () => { window.location.href = '/businesswebsite' }


    },
    {
        icon:<DisplaySettings />,
        name: "Business Details",
       
        productvarient: "Cotton",
        color: "#FCD09A",
        backgroundColor: "#FFE4C2",
        title: "view more",
        navigate: () => { window.location.href = '/leaderbusinessdetail' }
    },
  
    {
        icon:<CorporateFareSharp />,
        name: "About Company",
      
        productvarient: "Cotton",
        color: "#D2C2FF",
        backgroundColor: "#E5DCFF",
        title: "view more",
        navigate: () => { window.location.href = '/aboutcompany' }

    },
]