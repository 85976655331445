import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import SideBar from '../../Common/SideBar/SideBar';

const AboutCompany = () => {
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [companyId] = useState(localStorage.getItem('clientId'));

    useEffect(() => {
        fetchCompanyDescription();
    }, []);

    const fetchCompanyDescription = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/client_business_description/${companyId}`);
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Description fetched successfully');
                setDescription(data.ResponseData.description);
            } else {
                console.error('Error fetching description:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error fetching description:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/client_business_description-update/1`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ description })
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                console.log('Description updated successfully');
            } else {
                console.error('Error updating description:', data.ResponseText);
            }
        } catch (error) {
            console.error('Error updating description:', error);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                    <SideBar />
                </div>
                <div className='col-8 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <h2 className='mt-5 text-uppercase'>About Company</h2>
                    <p>Edits may go for moderation and it can take up to 24-48 hours to be published.</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} value={description} onChange={handleChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
}

export default AboutCompany;
