import React, { useState } from 'react';
import NAvbar from '../../Componenets/Layout/Navbar/NAvbar';
import DetailsPage from './DetailsPage/DetailsPage';
import Description from './Description/Description';
import ProductList from './ProductList/ProductList';
import CompanyDetails from './CompanyDetails/CompanyDetails';
import Footer from '../../Componenets/Layout/Footer/Footer';
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ProductDetails = () => {
  const [showPopup, setShowPopup] = useState(false); 

  const handlePopupClose = () => {
    setShowPopup(false); 
  };

  const handleGetPriceClick = () => {
    setShowPopup(true);
  };

  return (
    <>
      <DetailsPage />
      {/* <Description /> */}
      {/* <ProductList /> */}
      <CompanyDetails />
      <div className='d-flex d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none mb-2 align-items-center justify-content-between position-fixed bottom-0 end-0 w-100 '>
        <Button className='btnpink px-5 ms-2' onClick={handleGetPriceClick}>
          <i className="fa-solid fa-phone"/> Call
        </Button>
        <Button className='btnpink px-5 me-2' onClick={handleGetPriceClick}>
          <i className="fa-solid fa-tags"/> Get Best Price
        </Button>
      </div>
      
      {/* Popup Component */}
      <Modal show={showPopup} onHide={handlePopupClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>        
          <div>           
            <img src="product_image_url" alt="Product" />
          </div>
          <div>          
            <h3>Product Name</h3>         
            <p>Company Name</p>       
            <textarea rows="4" cols="50" placeholder="Comments"></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePopupClose}>
            Close
          </Button>
          <Button variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
         </>
  );
};

export default ProductDetails;
