import React from 'react'
import buy from '../../images/buyleads (1).png'
import dashboard from '../../images/buyleads (9).png'
import inquiry from '../../images/buyleads (2).png'
import productlist from '../../images/buyleads (3).png'
import profile from '../../images/buyleads (7).png'
import feature from '../../images/buyleads (6).png'
import international from '../../images/buyleads (8).png'
import trade from '../../images/buyleads (10).png'
import contact from '../../images/driving-license.png'
import image from '../../images/image-upload.png'
import video from '../../images/video-tutorials.png'
import socialmedia from '../../images/media-planning.png'
import website from '../../images/landing-page.png'
import details from '../../images/presentations.png'
import about from '../../images/book (1).png'
import service from '../../images/buyleads (4).png'
import google from '../../images/buyleads (5).png'
import { useNavigate } from 'react-router-dom'

const SideBar = () => {
    const navigate = useNavigate();
    return (
        <>
            <section >
                <div className='sidebar'>
                    <aside >
                        <div className="menu">
                            <ul className="list d-flex flex-column gap-3 mt-3 ps-2 ">
                                <li>
                                    <a onClick={() => navigate('/dashboard')} className='text-white pointer'>
                                        <img src={dashboard} className='img-fluid me-2' style={{ width: '35px' }} alt={dashboard} />
                                        <span>Dashboard</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/productlist')} className='text-white pointer'><img src={productlist} className='img-fluid me-2' style={{ width: '35px' }} /><span>Featured Products</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/inquiry')} className='text-white  pointer'>
                                        <img src={inquiry} className='img-fluid me-2' style={{ width: '35px' }} alt="" /><span>Inquiry</span></a>
                                </li>

                                <li>
                                    <a onClick={() => navigate('/contact')} className='text-white pointer'><img src={contact} className='img-fluid me-2' style={{ width: '35px' }} /><span>Contact</span></a>
                                </li>


                                <li>
                                    <a onClick={() => navigate('/imagepicker')} className='text-white pointer'><img src={image} className='img-fluid me-2' style={{ width: '35px' }} /><span>Image</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/video')} className='text-white pointer'><img src={video} className='img-fluid me-2' style={{ width: '35px' }} /><span>Video</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/dashboard')} className='text-white pointer'><img src={feature} className='img-fluid me-2' style={{ width: '35px' }} /><span>KYC</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/socialmedia')} className='text-white pointer'><img src={socialmedia} className='img-fluid me-2' style={{ width: '35px' }} /><span>Social Media</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/businesswebsite')} className='text-white pointer'><img src={website} className='img-fluid me-2' style={{ width: '35px' }} /><span> Business Website</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/leaderbusinessdetail')} className='text-white pointer'><img src={details} className='img-fluid me-2' style={{ width: '35px' }} /><span> Business Details </span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/aboutcompany')} className='text-white pointer'><img src={about} className='img-fluid me-2' style={{ width: '35px' }} /><span>About Comapny</span></a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </section>
        </>
    )
}
export default SideBar
