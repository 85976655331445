import React from 'react'
import { Container } from 'react-bootstrap'

const Deliverypolicy = () => {
  return (
   <>
   <section>
    <Container>
        <h1 className='mt-5 text-uppercase text-center'>Delivery Policy</h1>
    </Container>
   </section>
   </>
  )
}

export default Deliverypolicy
