import React, { useState } from 'react'
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import PaymentIcon from '@mui/icons-material/Payment';
import { Link } from 'react-router-dom';
import { Leaderboard } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


const styles = {
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#C10037',
    color: '#FFF',
    borderRadius: '20px 20px 0 0',

  },
};

const BottomNavigationn = () => {
  const [value, setValue] = useState('/');
  const navigate = useNavigate();
  const user_type = localStorage.getItem('userType');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isLoggedIn = localStorage.getItem('userId') !== null;


  const handleProfileClick = () => {
    scrollToTop();
    if (isLoggedIn) {
      navigate('/userprofile');
    } else {
      navigate('/login');
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

  return (
    <>
      <div className="homeFooter">
      <BottomNavigation sx={styles.root} value={value} onChange={handleChange}>
      <BottomNavigationAction
  style={{ width: "40px" }}
  label="Home"
  value="home"
  icon={<HomeIcon />}
  onClick={() => {
    navigate('/');
    scrollToTop();
  }}
/>
        {user_type === '2' && (
          <BottomNavigationAction
            label="Leaderboard"
            value="leaderboard"
            icon={<Leaderboard />}
           
            onClick={() => {
              navigate('/leaderboardd');
              scrollToTop();
            }}
          />
        )}
        <BottomNavigationAction
          label="Search"
          value="search"
          icon={<SearchIcon />}
                  onClick={() => {
            navigate('/search');
            scrollToTop();
          }}
        />
        <BottomNavigationAction
          label="Offer"
          value="Offer"
          icon={<LocalOfferIcon />}
                  onClick={() => {
            navigate('/offerzone');
            scrollToTop();
          }}
        />
        <BottomNavigationAction
          label="Account"
          value="account"
          icon={<AccountCircleIcon />}
          onClick={handleProfileClick}
        />
      </BottomNavigation>
    </div>
    </>
  )
}

export default BottomNavigationn

