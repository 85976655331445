import React from 'react';
import buy from '../../images/buyleads (1).png'
import dashboard from '../../images/buyleads (9).png'
import inquiry from '../../images/buyleads (2).png'
import productlist from '../../images/buyleads (3).png'
import profile from '../../images/buyleads (7).png'
import feature from '../../images/buyleads (6).png'
import international from '../../images/buyleads (8).png'
import trade from '../../images/buyleads (10).png'
import contact from '../../images/driving-license.png'
import image from '../../images/image-upload.png'
import video from '../../images/video-tutorials.png'
import socialmedia from '../../images/media-planning.png'
import website from '../../images/landing-page.png'
import details from '../../images/presentations.png'
import about from '../../images/book (1).png'
import service from '../../images/buyleads (4).png'
import google from '../../images/buyleads (5).png'
import { useNavigate } from 'react-router-dom'

const ProfileSidebar = () => {
    const navigate = useNavigate();
    return (
        <>
            <section >
                <div className='sidebar'>
                    <aside >
                        <div className="menu">
                            <ul className="list d-flex flex-column gap-3 mt-3        ">
                                {/* <li>
                                    <a onClick={() => navigate('/dashboard')} className='text-white pointer'>
                                        <img src={dashboard} className='img-fluid me-2' style={{ width: '35px' }} alt={dashboard} />
                                        <span>Profile</span>
                                    </a>
                                </li> */}
                                  <li>
                                    <a href="/userdashboardprofile"><img src={profile} className='img-fluid me-2' style={{ width: '35px' }} /><span>Profile</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/postyourrequirement')} className='text-white pointer'><img src={productlist} className='img-fluid me-2' style={{ width: '35px' }} /><span>Post Your Requirements</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/comingsoon')} className='text-white  pointer'>
                                        <img src={inquiry} className='img-fluid me-2' style={{ width: '35px' }} alt="" /><span>Notification</span></a>
                                </li>
                                {/* <li className='d-flex align-items-center gap-2  felx-column'>
                                    <a onClick={() => navigate('/buyleads')} className='text-white pointer'><img src={buy} className='img-fluid me-2' style={{ width: '35px' }} alt={buy} /><span> Lead Recent</span></a>
                                </li> */}
                                <li>
                                    <a onClick={() => navigate('/userprofilecontact')} className='text-white pointer'><img src={contact} className='img-fluid me-2' style={{ width: '35px' }} /><span>Contact Us</span></a>
                                </li>                             
                                <li>
                                    <a onClick={() => navigate('/comingsoon')} className='text-white pointer'><img src={video} className='img-fluid me-2' style={{ width: '35px' }} /><span>Help Center</span></a>
                                </li>
                                <li>
                                    <a onClick={() => navigate('/comingsoon')} className='text-white pointer'><img src={feature} className='img-fluid me-2' style={{ width: '35px' }} /><span>FAQ</span></a>
                                </li>                                                                
                            </ul>
                        </div>
                    </aside>
                </div>
            </section>
        </>
    )
}

export default ProfileSidebar

