import React from 'react'
import ProfileSidebar from '../../Common/ProfileSidebar/ProfileSidebar';
import { Container } from 'react-bootstrap';


const ComingSoon = () => {
  return (
   <>
      <Container fluid className='ps-0'>
      <div className='row gap-xxl-5'>
        <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
          <ProfileSidebar />
        </div>
        <div className='col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
          <h2 className='mt-5 text-uppercase'>Coming Soon</h2>
         <p>Coming Soon....</p>
        </div>
      </div>
    </Container>
   </>
  )
}

export default ComingSoon
