import React from 'react'
import { Container } from 'react-bootstrap'

const SubscribeTrade = () => {
  return (
    <>
    <section>
    <Container>
        <h1 className='mt-5 text-uppercase text-center'>Subscribe Trade Alerts</h1>
    </Container>
    </section>
    </>
  )
}

export default SubscribeTrade
