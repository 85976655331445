import React from 'react'
import CategoriesProductList from './CategoriesProductList/CategoriesProductList'
import FeaturedProduct from '../Home/FeaturedProduct/FeaturedProduct'
import AdvertisementBanner from '../Home/AdvertisementBanner/AdvertisementBanner'
import FeatureProducts3 from '../Home/FeatureProducts3/FeatureProducts3'
import Testimonial from '../Home/Testimonial/Testimonial'
import UpcomingTradeshow from '../Home/UpcomingTradeshow/UpcomingTradeshow'

const Categories = () => {
  return (
    <>
      <CategoriesProductList/>
      <FeaturedProduct />
      <AdvertisementBanner />
      <FeatureProducts3 />
      <Testimonial />
      <UpcomingTradeshow />
    </>
  )
}
export default Categories
