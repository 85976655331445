import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import ProfileSidebar from '../../Common/ProfileSidebar/ProfileSidebar';
import axios from 'axios';


const PostYourRequirement = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile_number: '',
        company_name: '',
        requirements: '',
        comments: '',
        city_name: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/post-inquiry`, formData);
            console.log('API response:', response.data);
          
        } catch (error) {
            console.error('Error submitting form:', error);
          
        }
    };
    return (
        <Container fluid className='ps-0'>
            <div className='row gap-xxl-5'>
                <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                 <ProfileSidebar />
                </div>
                <div className='col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 col-xxl-9'>
                    <h2 className='mt-5 text-uppercase'>Post Your Requirement</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" name="name" value={formData.name} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMobileNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter your mobile number" name="mobile_number" value={formData.mobile_number} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCompanyName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your company name" name="company_name" value={formData.company_name} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRequirement">
                            <Form.Label>Requirement</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter your requirement" name="requirements" value={formData.requirements} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formComment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter any additional comment" name="comments" value={formData.comments} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCityName">
                            <Form.Label>City Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your city name" name="city_name" value={formData.city_name} onChange={handleChange} />
                        </Form.Group>
                        <Button className='btn btnpink' type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default PostYourRequirement;
