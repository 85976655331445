import React, { useState, useEffect } from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CompanyDetails from '../ProductDetails/CompanyDetails/CompanyDetails'
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ProductList from './ProductList';
import style from './Productviem.module.css'
import { LocationCity, LocationCityOutlined, Phone, PhoneAndroid, PhoneAndroidOutlined, PhonelinkLockOutlined, VerifiedUserRounded } from '@mui/icons-material';


const ProductView = () => {
  const { ProductListId } = useParams();
  const [productDetail, setProductDetail] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [specifications, setSpecifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState('');
  const [clientsdetails, setClientsdetails] = useState([]);
  const userId = localStorage.getItem('userId')

  // come from api id

  const navigate = useNavigate();

  const handleShowModal = () => {
    if (userId) {
      setShowModal(true);
    } else {
      navigate('/login');
    }
    const handleShowMobileModal = () => {
      setShowModal(true);
    };
  };

  const handleCloseMobileModal = () => {
    setShowModal(false);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleInquirySubmit = () => {
    fetch(`${process.env.REACT_APP_API}/api/send-inquiry`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from_user_id: userId,
        to_user_id: productDetail.user_id, // Assuming productDetail is set properly
        product_id: ProductListId,
        description: description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ResponseCode === 1) {
          console.log('Inquiry sent successfully');
          toast.success('Inquiry sent successfully');
        } else {
          console.error('Error submitting inquiry:', data.ResponseText);
          toast.error('Enter inquiry description');
          handleShowModal();
        }
      })
      .catch((error) => {
        console.error('Error submitting inquiry:', error);
        toast.success('Inquiry sent successfully');
      });
    handleCloseModal();
  };


  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/product-detail/${ProductListId}`);
        const data = await response.json();

        if (data.ResponseCode === 1 && data.ResponseData.productDetail.length > 0) {
          const detail = data.ResponseData.productDetail[0];
          setProductDetail(detail);
          setSelectedImage(detail?.product_image);
          setSpecifications(detail?.products_specification);
          setClientsdetails(detail?.clients_details || []);

          // Storing company_id in local storage
          const companyId = detail.clients_details.length > 0 ? detail.clients_details[0].company_id : '';
          localStorage.setItem('company_id', companyId);
        } else {
          console.error('Error fetching product detail:', data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching product detail:', error);
      }
    };
    fetchProductDetail();
  }, [ProductListId]);






  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <section className='mt-0 mt-lg-5 mt-md-5 mt-xl-5 mt-xxl-5 ' >
        <Container fluid className='ps-xxl-5 pe-xxl-5' >
          {productDetail && (
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6 ">
                <div className={style.ProductImageFlex} >
                  <img src={selectedImage || productDetail.product_image} alt={`Sub Image ${selectedImage}`} />
                </div>
                <Carousel responsive={responsive} className='mt-3 '>
                  {productDetail.products_image.map((subImage, index) => (
                    <div className={style.mainImage}>
                      <img
                        key={index}
                        className={style.mainImagee}
                        src={subImage.image_url}
                        alt={`Sub Image ${index + 1}`}
                        onClick={() => handleImageClick(subImage.image_url)}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="col-12 col-lg-5 col-md-6 ">
                <div className="d-flex flex-column gap-3">
                  <h3 >{productDetail.name}</h3>
                  <span className="fs-5 fw-bold mb-3" style={{ color: 'var(--gray)' }}>
                    Price:- ₹ {productDetail.discounted_price ? productDetail.discounted_price : 'Ask Price'}{' '}
                    {productDetail.price &&
                      <span className="text-decoration-line-through fw-normal fs-6" style={{ color: 'var(--gray)' }}>
                        ₹ {productDetail.price}
                      </span>
                    }
                  </span>
                </div>
                <div>
                  <h4>Description</h4>
                  <p>{productDetail.description}</p>
                </div>
                {specifications.length > 0 && ( // Conditionally render the entire Specifications section
                  <div className='d-flex flex-column gap-1 border p-3'>
                    <h5>Specifications</h5>
                    <hr className='mb-3' />
                    <table className='table'>
                      <tbody>
                        {specifications.map((spec, index) => (
                          <tr key={index}>
                            <td className='fw-bold'>{spec.variant_name}</td>
                            <td>{spec.variant}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <a href="#">View Complete Details</a>
                  </div>
                )}
                {/* <div className='d-flex flex-column gap-3 border p-3'>
                  <h3>Sepicification</h3>
                  <hr className='mb-3' />
                  {specifications.map((spec, index) => (
                    <li key={index} className='ms-2 list-unstyled'>{spec.variant_name}: {spec.variant}</li>
                  ))}
                  <a href=""> View Complete detail</a>
                </div> */}
                <div>
                  <Button className="btnpink mt-3 mb-3" onClick={handleShowModal}>
                    Get Latest Price
                  </Button>
                </div>
                {/* Modal */}
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Inquiry</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <label htmlFor="Description">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder='Enter Description'
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                    <Button variant="primary" className="btnpink" onClick={handleInquirySubmit}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="col-12 col-lg-3 col-md-8 ">
                <div className='border border-1 '>
                  <div className='p-3' style={{ backgroundColor: 'lightgray' }}>
                    {clientsdetails.map((client, index) => (
                      <div key={index} className='d-flex align-items-center'>
                        <div style={{ width: '70px', height: '70px', overflow: 'hidden' }}>
                          <img src={client.logo} alt="" className='img-fluid' />
                        </div>
                        <div className='d-flex flex-column ms-3 gap-1'>
                          <div>
                            <h6 className='text-decoration-underline '>Seller Details</h6>
                          </div>
                          <div >
                            <a><span> <LocationCityOutlined /> {client.cityname}</span></a>
                          </div>
                          <div>
                            <a><img src={client.icon_url} className='img-fluid' style={{ width: '25px', height: '25px' }} alt="verified" />{client.trust_verified_name}</a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr />
                  <div className='p-3 m-4 ' style={{ backgroundColor: 'lightgray', borderRadius: '10px' }}>
                    <div className='d-flex justify-content-center'>
                     <PhoneAndroidOutlined />
                      <span >View Mobile Number </span>
                    </div>
                  </div>
                  
                  <div className=' m-3 '>
                    {clientsdetails.length > 0 ? (
                      <button className='btnpink w-100 p-3' onClick={() => navigate(`/productdetails/${ProductListId}`)}><PhonelinkLockOutlined /> Contact Supplier</button>
                    ) : (
                      <button disabled className='btnpink w-100 p-3'><PhonelinkLockOutlined />Contact Supplier</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </section>
      <ProductList />
      {/* <CompanyDetails /> */}
      <ToastContainer />
    </>
  )

}

export default ProductView
