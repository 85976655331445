import React from 'react'
import { Button, Container } from 'react-bootstrap'
import style from './BuyLeads.module.css'
import { BUYLEADS } from './BuyLeads.utils'
import SideBar from '../../Common/SideBar/SideBar'

const BuyLeads = () => {
    return (
        <>
            <section id='buyLead'>
              <Container fluid className='ps-0'>
              <div id='buyLeads' className='row'>
                    <div className='col-4 col-md-6 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
                        <SideBar />
                    </div>
                    <div className='col-7 col-md-6 col-lg-10 col-xl-10 col-xxl-9 mt-1'>
                        <div className='text-end mb-3 mt-3'>
                            <Button className='btnpink px-5 '>Buy Leads</Button>
                        </div>
                        {
                            BUYLEADS.map((item, index) => (
                                <div className={style.buyFlex}>
                                    <div>
                                        <h3>Custom Website Development Service</h3>
                                        <div className='d-flex align-items-center gap-3'>
                                            <span>55 mins ago</span>
                                            <span>Godhra, Gujarat</span>
                                        </div>
                                        <p>I am looking for Custom Website Development
                                            Service.
                                            Kindly send me price and other details.</p>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <Button className='btnwhite px-5 '>Contact Buyer Now</Button>
                                    </div>
                                </div>

                            ))
                        }

                    </div>
                </div>
              </Container>
            </section>
        </>
    )
}

export default BuyLeads
