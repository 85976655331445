import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../Common/Header/Header';

const PrivacyPolicy = () => {
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/privacypolicy`);
        const data = await response.json();
        setResponseData(data.ResponseData[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
   
    <>
    <Header name={"Privacy Policy"} subname={"Privacy Policy"}/>
   <Container>
        <div className="App mt-5 mb-5">
          <div className="separator">
          {responseData && (
              <div
              className="text-left"
              style={{ fontSize: "16px", lineHeight: "1.6", color: "black" , textAlign: "justify"}}
              dangerouslySetInnerHTML={{ __html: responseData.description }} />
            )}
          </div>
        </div>
      </Container>
   </>
  )
}

export default PrivacyPolicy
