import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Container } from 'react-bootstrap';
import Header from '../../Common/Header/Header';
import axios from 'axios';
import SignaturePad from 'react-signature-canvas';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PricingPlanProfile = () => {
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState([]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/categorylist`);
        setCountryData(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching categories: ', error);
      }
    };

    const fetchState = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/state-list`);
        setStateData(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching subcategories: ', error);
      }
    };

    // const fetchCitys = async () => {
    //   try {
    //     const response = await axios.get(`${process.env.REACT_APP_API}/api/city-list`);
    //     setCityData(response.data.ResponseData);
    //   } catch (error) {
    //     console.error('Error fetching citys: ', error);
    //   }
    // };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/categorylist`);
        setCategoryData(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching categories: ', error);
      }
    };

    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/subcategory-list`);
        setSubcategoryData(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching subcategories: ', error);
      }
    };


    // fetchCitys();
    fetchCountry();
    fetchState();
    fetchCategories();
    fetchSubcategories();
  }, []);
  const userId = localStorage.getItem("userId");
  const [formData, setFormData] = useState({
    user_id: userId,
    company_name: '',
    country_id: '1',
    subcate_id: '',
    address: '',
    state_id: '',
    city_id: '',
    contact1: '',
    company_type: '',
    pancard_number: '',
    aadharcard_number: '',
    gst_number: '',
    description: '',
    contact2: '',
    gst_comment: '',
    signature_url: null,
    selfie_image: '',
    logo: '',
    gst_image: '',
    aadharcardimage: '',
    pancard_image: '',
    clients_type: '',

  });

  console.log(formData);
  const handleInputChange = (e) => {

    const { name, value } = e.target;

    if (name === "state_id") {
      axios
        .post(`${process.env.REACT_APP_API}/api/city-list-statewise`, {
          state_id: value,
        })
        .then((response) => {
          setCityData(response.data.ResponseData);
        })
        .catch((error) => {
          console.error("Error fetching city data:", error);
        });
    }


    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create an array to store the names of the required fields that are not filled
    // const missingFields = [];
  
    // // Validate the required fields
    // const requiredFields = ['Company Name', 'country', 'Address', 'State', 'City', 'Mobile Number', 'Company Type', 'Description', 'Mobile Number', 'Clients Type', 'Pancard Number', 'Aadharcard Number', 'GST Number', 'Signature', 'Selfie', 'Logo', 'GST Image', 'Aadharcard Image', 'Pancard Image']; // Replace with your actual field names
  
    // requiredFields.forEach((field) => {
    //   if (!formData[field]) {
    //     missingFields.push(field);
    //   }
    // });
  
    // // If any required fields are not filled, show a toast message
    // if (missingFields.length > 0) {
    //   const missingFieldNames = missingFields.join(', ');
    //   toast.error(`Please fill in the following fields: ${missingFieldNames}`);
    //   return;
    // }
  
    try {
      const formDataToSend = new FormData();
  
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });
  
      Object.keys(formData).forEach((key) => {
        if (formData[key] instanceof File) {
          formDataToSend.append(key, formData[key]);
        }
      });
  
      const response = await fetch(`${process.env.REACT_APP_API}/api/addclients`, {
        method: 'POST',
        body: formDataToSend,
      });
  
      const data = await response.json();
  
      if (data.ResponseCode === 1) {
        localStorage.setItem('clientId', data.ResponseData.id);
        console.log('Client added successfully:', data);
        toast.success('Client added successfully');
        navigate('/dashboard');
      } else {
        console.error('Error adding client:', data.ResponseText);
        toast.error('Error adding client');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error adding client');
    }
  };
  
  console.log(formData);
  const signatureRef = useRef();
  const handleClear = () => {
    signatureRef.current.clear();
  };
  const handleSave = () => {
    const signatureImage = signatureRef.current.toDataURL();
    // Now you can save or use the signatureImage as needed.
    console.log(signatureImage);
  };
  return (
    <>
      <section >
        <Header name={"Business plan"} subname={"Registration"} />
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <div className='mt-50 Headregistration' data-aos="fade-up" data-aos-duration="2000">
            <h1 className='mb-5 text-center Registration '>Registration For Buiness Plan</h1>
            <div className='row'>
              <div className='col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                <div className='d-flex flex-column gap-4 w-75 regiFlex'>
                  <input type="text" placeholder='Business name' className='px-3 py-2 border rounded' name='company_name' value={formData.company_name} onChange={handleInputChange} />
                  <input type="text" placeholder='Mobile number' className='px-3 py-2 border rounded' maxLength={10} name='contact1' value={formData.contact1} onChange={handleInputChange} />
                  <input type="text" placeholder='Mobile number' className='px-3 py-2 border rounded' maxLength={10} name='contact2' value={formData.contact2} onChange={handleInputChange} />
                  <input type="text" placeholder='address' className='px-3 py-2 border rounded' name='address' value={formData.address} onChange={handleInputChange} />
                  {/* <input type="text" placeholder='select country' className='px-3 py-2 border rounded'  /> */}
                  <div>
                    <select name="country_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} required>
                      <option value={formData.country_id ? "" : ""} >
                        select country
                      </option>
                      {/* {countryData &&
                        countryData.map((v, index) => {
                          return (
                            <option value={v.country_name} key={index.country_name}>
                              {v.country_name}
                            </option>
                          );
                        })} */}
                    </select>
                  </div>
                  {/* <input type="text" placeholder='select state' className='px-3 py-2 border rounded' /> */}
                  <div className='d-flex justify-content-between  gap-4'>
                    <div className='d-flex flex-column   w-50'>
                      <select name="state_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} required>
                        <option value={formData.state_id ? "" : ""} >
                          select state
                        </option>
                        {stateData &&
                          stateData.map((v, index) => {
                            return (
                              <option value={v.state_id} key={index.id}>
                                {v.statename}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className='d-flex flex-column  w-50'>
                      <select name="city_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} required>
                        <option value={formData.city_id ? "" : ""} >
                          select city
                        </option>
                        {cityData &&
                          cityData.map((v, index) => {
                            return (
                              <option value={v.id} key={index.id}>
                                {v.cityname}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mb-3  gap-4'>
                    <div className='d-flex flex-column   w-50'>
                      <select name='clients_type' className='form-control show-tick ms select2' data-placeholder="Select" onChange={handleInputChange} required >
                        <option value={formData.clients_type ? "" : ""} >
                          select clients type
                        </option>
                        <option value={1}>Service Provider</option>
                        <option value={2}>Product Seller</option>
                      </select>

                    </div>
                    <div className='d-flex flex-column  w-50'>

                      <select name='company_type' className='form-control show-tick ms select2' data-placeholder="Select" onChange={handleInputChange} required >
                        <option value={formData.company_type ? "" : ""} >
                          select company type
                        </option>
                        <option value={1}>Private Limited</option>
                        <option value={2}>Public Limited</option>
                      </select>

                    </div>
                  </div>
                  {/* <input type="text" placeholder='select city' className='px-3 py-2 border rounded' /> */}

                  {/* <input type="text" placeholder='select company type' className='px-3 py-2 border rounded' /> */}

                  <input type="text" placeholder='Gst comment' className='px-3 py-2 border rounded' name='gst_comment' value={formData.gst_comment} onChange={handleInputChange} />
                </div>
              </div>
              <div className='col-12  col-md-6 col-lg-6 col-xl-6 col-xxl-6 businessFlex'>
                <div className='d-flex flex-column gap-4 w-75 regiFlex'>
                  <input type="text" placeholder='Enter description' className='px-3 py-2 border rounded' name='description' value={formData.description} onChange={handleInputChange} />
                  {/* <div>
                    <select name="category_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} required>
                      <option value={formData.category_id ? "" : ""} >
                        select category
                      </option>
                      {categoryData &&
                        categoryData.map((v, index) => {
                          return (
                            <option value={v.id} key={index.id}>
                              {v.category_name}
                            </option>
                          );
                        })}
                    </select>
                  </div> */}
                
                  <div>
                    <select name="subcate_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} required>
                      <option value={formData.subcate_id ? "" : ""} >
                        --select category--
                      </option>
                      {subcategoryData &&
                        subcategoryData.map((v, index) => {
                          return (
                            <option value={v.id} key={index.id}>
                              {v.subcategory_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className='d-flex  align-items-center justify-content-between panImage'>
                    <input type="text" placeholder='Pan card Number' className='px-3 py-2 border rounded pancard' name='pancard_number' maxLength={10} value={formData.pancard_number} onChange={handleInputChange} />
                    <input type="file" accept="image/*" name="pancard_image" onChange={handleFileInputChange} />
                  </div>

                  <div className='d-flex  align-items-center justify-content-between panImage'>
                    <input type="number" placeholder='Adhar card Number' className='px-3 py-2 border rounded pancard' onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 12);
                    }} name='aadharcard_number' value={formData.aadharcard_number} onChange={handleInputChange} />
                    <input type="file" accept="image/*" name='aadharcardimage' onChange={handleFileInputChange} />
                  </div>

                  <div className='d-flex  align-items-center justify-content-between panImage'>
                    <input type="text" placeholder='GST Number' className='px-3 py-2 border rounded pancard' name='gst_number' maxLength={15} value={formData.gst_number} onChange={handleInputChange} />
                    <input type="file" accept="image/*" name='gst_image' onChange={handleFileInputChange} />
                  </div>
                  {/* Other input fields */}
                  <div className='d-flex  align-items-center justify-content-between businessLogo '>
                    <label htmlFor="" className='px-3 py-2 border rounded business'>Business logo</label>
                    <input type="file" accept="image/*" name='logo' onChange={handleFileInputChange} />
                  </div>
                  <div className='d-flex  align-items-center justify-content-between businessLogo'>
                    <label htmlFor="" className='px-3 py-2 border rounded business'>Selfie image </label>
                    <input type="file" accept="image/*" name='selfie_image' onChange={handleFileInputChange} />
                  </div>
                  <div className='d-flex  align-items-center justify-content-between businessLogo'>
                    <label htmlFor="" className='px-3 py-2 border rounded business'>Signature </label>
                    <input type="file" accept="image/*" name='signature_url' onChange={handleFileInputChange} />
                  </div>
                  <div className='d-flex align-items-center justify-content-between '>
                    <label htmlFor="">Draw Signature </label>
                    <div className='px-3 py-2 border rounded' style={{ border: '1px solid #ddd' }}>
                      <SignaturePad
                        canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
                        ref={signatureRef}
                      />
                    </div>
                  </div>
                  <div className='d-flex  align-items-center justify-content-end gap-3'>
                    <button className='btn btnpink text-white px-3' onClick={handleClear}>Clear</button>
                    <button className='btn btnpink text-white px-3' onClick={handleSave}>Save</button>
                  </div>
                  <label>
                    <input type="checkbox" className='me-2' />
                    I accept the terms and conditions
                  </label>
                  {/* Submit button */}
                  <form onSubmit={handleSubmit} >
                    <div className='d-flex   justify-content-center '>
                      <button type="submit" className='btn btnpink text-white px-5'>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <ToastContainer />
    </>
  )
}
export default PricingPlanProfile
