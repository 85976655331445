import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import ProfileSidebar from '../../Common/ProfileSidebar/ProfileSidebar';

const UserProfileContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact_number: '',
    email_id: '',
    description: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log('API Response:', data);
      
      // Clear form fields after successful submission
      setFormData({
        name: '',
        contact_number: '',
        email_id: '',
        description: ''
      });
  
      // Handle success response here
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Container fluid className='ps-0'>
      <div className='row gap-xxl-5'>
        <div className='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mt-1'>
       <ProfileSidebar />
        </div>
        <div className='col-7 col-sm-7 col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
          <h2 className='mt-5 text-uppercase'>Contact</h2>
          <Form onSubmit={handleSubmit} validate>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContact">
              <Form.Label>Contact</Form.Label>
              <Form.Control type="text" name="contact_number" placeholder="Enter your contact number" required value={formData.contact_number} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email_id" placeholder="Enter your email address" value={formData.email_id} onChange={handleChange} required  />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" placeholder="Enter your message" value={formData.description} onChange={handleChange} required />
            </Form.Group>

            <Button className='btn btnpink'  type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
}

export default UserProfileContact;
