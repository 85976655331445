import React from 'react'
import { Container } from 'react-bootstrap'

const HelpCenter = () => {
  return (
   <section>
    <Container>
      <h1 className='mt-5 text-uppercase text-center'>Help Center</h1>
    </Container>
   </section>
  )
}

export default HelpCenter
