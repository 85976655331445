import React, { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input';
import { Button, Col, Container, Row } from 'react-bootstrap'
import loginimag from '../../images/loginImage.png'
import style from './Otp.module.css'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

function Otp() {
    const [otp, setOtp] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || ''); // Retrieve phone number from localStorage
    const [seconds, setSeconds] = useState(Math.floor(Math.random() * 60));
    const [isResendEnabled, setIsResendEnabled] = useState(true);
    const navigate = useNavigate();
    
    useEffect(() => {
        const intervalId = setInterval(() => {
          
            setSeconds(prevSeconds => (prevSeconds === 0 ? 59 : prevSeconds - 1));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setPhoneNumber(localStorage.getItem('phoneNumber') || '');
    }, []);
   
    const handleOTPVerification = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/otp-verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    login_type: 'phone',
                    phone: phoneNumber,
                    otp: otp,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            if (responseData.ResponseText === 'Login successfully') {
                localStorage.setItem('userToken', responseData.ResponseData.token);
                localStorage.setItem('userId', responseData.ResponseData.user.id);

                const userType = responseData.ResponseData.user.user_type ;
                localStorage.setItem('userType', userType);

                if (responseData.ResponseData.user.name === null || responseData.ResponseData.user.email === null) {
                   
                    navigate('/businessdetails');
                    toast.success('Login successfully');

                } else {
                 
                    navigate('*');
                    toast.success('Login successfully');
                }
            } else {
                
                console.error('OTP verification failed:', responseData.ResponseText);
                toast.error('OTP verification failed:', responseData.ResponseText);
            }
        } catch (error) {
            console.error('Error during OTP verification:', error.message);
            toast.error('Enter valid OTP ');
        }
    };

    // Resend OTP handler//
    const handleResendClick = async () => {
        try {
            const resendResponse = await fetch(`${process.env.REACT_APP_API}/api/resend-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    login_type: 'phone',
                    phone: phoneNumber,
                }),
            });

            if (!resendResponse.ok) {
                throw new Error('Network response for resend was not ok');
            }

            const resendData = await resendResponse.json();

            if (resendData.ResponseCode === 1) {
                setSeconds(60);
                setIsResendEnabled(false);
                toast.success(resendData.ResponseText);
            } else {
                console.error('Resend OTP failed:', resendData.ResponseText);
                toast.error('Resend OTP failed:', resendData.ResponseText);
            }
        } catch (error) {
            console.error('Error during OTP resend:', error.message);
            toast.error('Error during OTP resend:', error.message);
        }
    };
    return (
        <>

            <Container fluid className='ps-xxl-5 '>
                <Row>
                    <Col lg={6} md={6}  className='ps-4 mt-5' data-aos="fade-up" data-aos-duration="2000"  >
                    <div className={style.loginimagee}>
                            <img src={loginimag} className={style.loginimage} />
                        </div>
                    </Col>
                    <Col lg={6} md={6} className='ps-4' data-aos="fade-up" data-aos-duration="2000" >
                        <div className='mt-5 d-flex flex-column gap-1 gap-xxl-3  loginn '>
                            <h1 className={style.OTPTitle}>Login !</h1>
                            <span className={style.otpPlease}>Please login to continue with us</span>
                            <p className={style.otpEnter}>Enter the 6-digit code we sent to +91 {phoneNumber}</p>
                            <form className='d-flex flex-column w-75 ms-0 otp gap-1'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputStyle="otpInputField"
                                    renderInput={(props) => <input {...props} />}
                                />
                                <span className={style.otpsecond}>You will be able to resend OTP after :{seconds} seconds</span>
                                <span className={style.numberotp}>An OTP has been sent to +91 {phoneNumber}. you may not receive the OTP if the number is not registered with CloudRek.</span>
                                <div className='d-flex align-items-center gap-3 xxl-mb-4'>
                                    <Button className='btnpink px-5' onClick={handleOTPVerification}>Login</Button>
                                    <Button className='btnpink px-5 resend' onClick={handleResendClick} disabled={!isResendEnabled}>Resend OTP</Button>
                                </div>
                            </form>
                            <span className={style.clicklogin}>By clicking Login, I agree to PG Click
                                <br />
                                <a href="">Terms of Use</a> and <a href="">Privacy Policy</a>.</span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    )
}

export default Otp