import React from 'react';
import style from './EventExhibition.module.css';
import banner from '../../images/banner-1.jpg';
import { Container } from 'react-bootstrap';

const EventExhibition = () => {
    return (
        <>
            <section >
                <Container fluid className='px-xxl-5'>
                    <h1 className='mt-5' style={{ color: 'var(--gray)' }}>Event & Exhibition</h1>
                    <p>PG Click organize business meetingonce in a month to grow business network and refreaal predefined Aggrement.Ther are two details powerpoint presentation or product demo in meeting or anywokshop.</p>
                    <div className='row'>
                        <div className='col-3'>
                            <div className={style.businessFlex}>
                                <img src={banner} alt="" className={style.businessImg} />                        
                            <div className='p-3'>
                                <span className='fw-bold' style={{ color: 'var(--pink)' }}>Dec 20, 2022</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default EventExhibition
