import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import style from './LeaderBoard.module.css';
import { BottomNavigationn } from '../../Componenets/BottomNavigation';
import { LEADERBOARD } from './LeaderBoard.utils';

const LeaderBoard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/seller-dashboard/${localStorage.getItem('userId')}`);
        const jsonData = await response.json();
        setDashboardData(jsonData);

        if (jsonData && jsonData.ResponseData && jsonData.ResponseData.clientsList.length > 0) {
          const companyId = jsonData.ResponseData.clientsList[0].company_id;
          localStorage.setItem('clientId', companyId);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className='d-flex'>
        <Container fluid className='ps-xxl-5 pe-xxl-5'>
          <h5>Welcome To,</h5>
          <h4 className='fst-italic'>{dashboardData?.ResponseData?.clientsList[0]?.company_name}</h4>
          <div className='row mt-3'>
            {/* Render API-driven boxes */}
            {dashboardData && dashboardData.ResponseData && (
              <>
                {/* Render box for productList */}
                <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2'>
                  <div className={style.leaderFlex} onClick={LEADERBOARD[0].navigate} style={{ backgroundColor: LEADERBOARD[0].backgroundColor, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>
                    <div className='d-flex flex-column px-3 py-2'>
                      <span>{LEADERBOARD[0].icon}</span>
                      <span>{LEADERBOARD[0].name}</span>
                      <span>{dashboardData.ResponseData.productList}</span>
                    </div>
                    <div className={style.ProductSquare} style={{ backgroundColor: LEADERBOARD[0].color }}>
                      <a href="#" className='text-black fw-bold' onClick={LEADERBOARD[0].navigate}>{LEADERBOARD[0].title}</a>
                    </div>
                  </div>
                </div>

                {/* Render box for clientssendinquiry */}
                <div className='col-12 col-sm-6 col-md-4  col-lg-3 col-xl-2 mb-2'>
                  <div className={style.leaderFlex} onClick={LEADERBOARD[1].navigate} style={{ backgroundColor: LEADERBOARD[1].backgroundColor, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>
                    <div className='d-flex flex-column px-3 py-2'>
                      <span>{LEADERBOARD[1].icon}</span>
                      <span>{LEADERBOARD[1].name}</span>
                      <span>{dashboardData.ResponseData.clientssendinquiry}</span>
                    </div>
                    <div className={style.ProductSquare} style={{ backgroundColor: LEADERBOARD[1].color }}>
                      <a href="#" className='text-black fw-bold' onClick={LEADERBOARD[1].navigate}>{LEADERBOARD[1].title}</a>
                    </div>
                  </div>
                </div> 
              </>
            )}

            {/* Render static boxes */}
            {LEADERBOARD.slice(2).map((item, index) => (
              <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2' key={index}>
                <div className={style.leaderFlex}  onClick={item.navigate} style={{ backgroundColor: item.backgroundColor, boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)' }}>
                  <div className='d-flex flex-column px-3 py-2'>
                    <span>{item.icon}</span>
                    <span>{item.name}</span>
                    <span>{item.number}</span>
                  </div>
                  <div className={style.ProductSquare} style={{ backgroundColor: item.color }}>
                    <a href="#" className='text-black fw-bold' onClick={item.navigate}>{item.title}</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <BottomNavigationn />
    </>
  );
};

export default LeaderBoard;
